import { useEffect, useRef } from 'react';

export const useAudioVisualizer = ({ canvasRef, isListening, isAiSpeaking }) => {
  const animationFrameRef = useRef();
  const dataArrayRef = useRef(new Uint8Array(128));
  const timeRef = useRef(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const draw = () => {
      const width = canvas.width;
      const height = canvas.height;
      ctx.clearRect(0, 0, width, height);

      if (isAiSpeaking) {
        // Smooth wave animation for AI speech
        timeRef.current += 0.05;
        const amplitude = height * 0.25;
        const frequency = 0.02;
        
        ctx.beginPath();
        ctx.moveTo(0, height / 2);

        for (let x = 0; x < width; x++) {
          const y = height / 2 + 
            Math.sin(x * frequency + timeRef.current) * amplitude * 
            Math.sin(timeRef.current * 0.5);
          ctx.lineTo(x, y);
        }

        // Gradient for AI speech
        const gradient = ctx.createLinearGradient(0, 0, width, 0);
        gradient.addColorStop(0, '#FF69B4');
        gradient.addColorStop(1, '#5BBCFF');
        
        ctx.strokeStyle = gradient;
        ctx.lineWidth = 3;
        ctx.stroke();
      } else if (isListening) {
        // Subtle pulsing circle for user input
        const centerX = width / 2;
        const centerY = height / 2;
        const maxRadius = Math.min(width, height) * 0.3;
        const pulseSpeed = 0.005;
        
        timeRef.current += pulseSpeed;
        const scale = Math.sin(timeRef.current) * 0.2 + 0.8; // Pulse between 0.6 and 1.0
        
        ctx.beginPath();
        ctx.arc(centerX, centerY, maxRadius * scale, 0, Math.PI * 2);
        ctx.strokeStyle = '#5BBCFF';
        ctx.lineWidth = 2;
        ctx.stroke();

        // Inner circle
        ctx.beginPath();
        ctx.arc(centerX, centerY, maxRadius * scale * 0.7, 0, Math.PI * 2);
        ctx.strokeStyle = 'rgba(91, 188, 255, 0.5)';
        ctx.stroke();
      }

      animationFrameRef.current = requestAnimationFrame(draw);
    };

    timeRef.current = 0;
    draw();

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [canvasRef, isListening, isAiSpeaking]);
}; 