import React, { useState, useEffect, useCallback } from 'react'
import {
  fetchFoldersWithRecordings,
  createFolder,
  deleteRecording,
  renameItem
} from '../DatabaseService'
import {
  FolderOutlined,
  PlusOutlined,
  UpOutlined,
  DownOutlined,
  ClockCircleOutlined,
  CalendarOutlined
} from '@ant-design/icons'
import {
  Button,
  Input,
  message,
  Card,
  List,
  Table,
  Typography,
  Popconfirm,
  Space
} from 'antd'
import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import '../styles/FileManagement.css'
import { useDarkMode } from '../App'
import { Resizable } from 'react-resizable'
import 'react-resizable/css/styles.css'
import FileUpload from './FileUpload'
import PrimaryButton from './ui/Buttons/primaryButton'
import DefaultButton from './ui/Buttons/defaultButton'
import { MdAudiotrack } from 'react-icons/md'
import { FaVideo } from 'react-icons/fa6'
// import { CgScreen } from "react-icons/cg";
import GroupsIcon from '@mui/icons-material/Groups'
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows'

const { Title, Text } = Typography

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className='react-resizable-handle'
          onClick={e => {
            e.stopPropagation()
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}

const FileManagement = ({ user }) => {
  const navigate = useNavigate()
  const [fileStructure, setFileStructure] = useState({
    folders: [],
    rootRecordings: []
  })
  const [currentFolder, setCurrentFolder] = useState(null)
  const [error, setError] = useState(null)
  const [newFolderName, setNewFolderName] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [sortColumn, setSortColumn] = useState('created_at')
  const [sortDirection, setSortDirection] = useState('desc')
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [isBulkDeleting, setIsBulkDeleting] = useState(false)
  const [showFileUpload, setShowFileUpload] = useState(false)
  const [deletingRecordingId, setDeletingRecordingId] = useState(null)
  const { isDarkMode } = useDarkMode()
  const [columns, setColumns] = useState([])
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [currentAudio, setCurrentAudio] = useState(null)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    setError(null)
    try {
      const data = await fetchFoldersWithRecordings(user.id)
      // data.rootRecordings.forEach(item => item.name === 'Innovative_Solutions_for_Digital_Marketers_Today' && console.log(item))
      // console.log('DATAAAA: ', data)
      setFileStructure(data)
    } catch (error) {
      console.error('Error fetching data:', error)
      setError('Failed to fetch data. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }, [user.id])

  useEffect(() => {
    if (user) {
      fetchData()
    }
  }, [user, fetchData])

  const handleCreateFolder = async e => {
    e.preventDefault()
    if (!newFolderName.trim()) {
      message.warning('Folder name cannot be empty.')
      return
    }

    try {
      const newFolder = await createFolder(
        user.id,
        newFolderName,
        currentFolder
      )
      setFileStructure(prevState => ({
        ...prevState,
        folders: [...prevState.folders, { ...newFolder, recordings: [] }]
      }))
      setNewFolderName('')
      message.success('Folder created successfully.')
    } catch (error) {
      console.error('Error creating folder:', error)
      setError('Failed to create folder. Please try again.')
      message.error('Failed to create folder.')
    }
  }

  const RecordingCard = ({
    recording,
    onEdit,
    onDelete,
    onPlay,
    isDeleting
  }) => (
    <Card
      style={{ backgroundColor: 'var(--div-bg-color)' }}
      size='small'
      // className='recording-card'
      actions={[
        recording.type === 'audio' ? (
          <MdAudiotrack
            fontSize={'16px'}
            style={{margin:'auto',lineHeight:'22px'}}
            key='audio'
            color='#8883e0'
            onClick={() => onPlay(recording.id)}
          />
        ) : recording.type === 'video' ? (
          <FaVideo
            key='video'
            color='#FFA07A'
            onClick={() => onPlay(recording.id)}
          />
        ) : recording.type === 'screen' ? (
          <DesktopWindowsIcon
            key='screen'
            color='#90EE90'
            onClick={() => onPlay(recording.id)}
          />
        ) : recording.type === 'meeting' ? (
          <GroupsIcon
            key='meeting'
            color='#D8BFD8'
            onClick={() => onPlay(recording.id)}
          />
        ) : (
          <PlayCircleOutlined
            key='play'
            className={`action-icon`}
            onClick={() => onPlay(recording.id)}
          />
        ),
        <EditOutlined
          key='edit'
          style={{ color: '#04a3ff' }}
          onClick={() => onEdit(recording.id)}
        />,
        <Popconfirm
          title='Are you sure you want to delete this recording?'
          onConfirm={() => onDelete(recording.id)}
          okText='Yes'
          cancelText='No'
          okButtonProps={{ loading: isDeleting }}
        >
          <DeleteOutlined key='delete' style={{ color: '#ff4d4f' }} />
        </Popconfirm>
      ]}
    >
      <Card.Meta
        style={{ backgroundColor: 'var(--div-bg-color)' }}
        title={
          <Text style={{ color: 'var(--text-color)', fontFamily: '"Roboto", "Helvetica Neue", sans-serif', fontWeight: '100' }} ellipsis>
            {recording.name}
          </Text>
        }
        // description={
        //   <Space
        //     direction='vertical'
        //     size={0}
        //     style={{ backgroundColor: 'var(--div-bg-color)' }}
        //   >
        //     <Text style={{ color: 'var(--sec-text-color)' }} type='secondary'>
        //       {recording.dateCreated}
        //     </Text>
        //     <Text style={{ color: 'var(--sec-text-color)' }} type='secondary'>
        //       {recording.duration}
        //     </Text>
        //   </Space>
        // }
      />
    </Card>
  )

  const handleFileUpload = acceptedFiles => {
    console.log('Uploaded files:', acceptedFiles)
    setShowFileUpload(false)
    // Implement the file upload logic here
  }

  const handleDeleteFile = async fileId => {
    try {
      const result = await deleteRecording(fileId)
      if (result.success) {
        setFileStructure(prevState => ({
          ...prevState,
          rootRecordings: prevState.rootRecordings.filter(
            rec => rec.id !== fileId
          ),
          folders: prevState.folders.map(folder => ({
            ...folder,
            recordings: folder.recordings.filter(rec => rec.id !== fileId)
          }))
        }))
        setError(null)
        message.success('File deleted successfully.')
      } else {
        setError(result.error || 'Failed to delete file. Please try again.')
        message.error(result.error || 'Failed to delete file.')
      }
    } catch (error) {
      console.error('Error deleting file:', error)
      setError(`Failed to delete file: ${error.message}`)
      message.error(`Failed to delete file: ${error.message}`)
    }
  }

  const handleRenameItem = async (itemId, newName, isFolder) => {
    if (!newName.trim()) {
      message.warning('Name cannot be empty.')
      return
    }

    try {
      await renameItem(itemId, newName, isFolder)
      if (isFolder) {
        setFileStructure(prevState => ({
          ...prevState,
          folders: prevState.folders.map(folder =>
            folder.id === itemId ? { ...folder, name: newName } : folder
          )
        }))
      } else {
        setFileStructure(prevState => ({
          ...prevState,
          rootRecordings: prevState.rootRecordings.map(rec =>
            rec.id === itemId ? { ...rec, name: newName } : rec
          ),
          folders: prevState.folders.map(folder => ({
            ...folder,
            recordings: folder.recordings.map(rec =>
              rec.id === itemId ? { ...rec, name: newName } : rec
            )
          }))
        }))
      }
      message.success('Item renamed successfully.')
    } catch (error) {
      console.error('Error renaming item:', error)
      setError('Failed to rename item. Please try again.')
      message.error('Failed to rename item.')
    }
  }

  const openPlayerScreen = useCallback(
    recordingId => {
      console.log('FileManagement: Opening PlayerScreen with ID:', recordingId)
      if (navigate) {
        navigate(`/player/${recordingId}`)
      } else {
        console.error('Navigation function is not available')
      }
    },
    [navigate]
  )

  const handleSort = useCallback(
    column => {
      if (sortColumn === column) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
      } else {
        setSortColumn(column)
        setSortDirection('desc')
      }
    },
    [sortColumn, sortDirection]
  )

  const sortedRecordings = () => {
    const recordings =
      currentFolder === null
        ? fileStructure.rootRecordings
        : fileStructure.folders.find(f => f.id === currentFolder)?.recordings ||
          []

    return [...recordings].sort((a, b) => {
      let aValue = a[sortColumn]
      let bValue = b[sortColumn]

      if (sortColumn === 'created_at' || sortColumn === 'updated_at') {
        aValue = new Date(aValue)
        bValue = new Date(bValue)
      }

      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1
      return 0
    })
  }

  const renderSortIcon = useCallback(
    column => {
      if (sortColumn !== column) return null
      return sortDirection === 'asc' ? (
        <UpOutlined className='sort-icon' />
      ) : (
        <DownOutlined className='sort-icon' />
      )
    },
    [sortColumn, sortDirection]
  )

  const formatDuration = seconds => {
    if (!seconds) return 'N/A'
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.round(seconds % 60)
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
  }

  const handleResize =
    index =>
    (e, { size }) => {
      const newColumns = [...columns]
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      }
      setColumns(newColumns)
    }

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const handlePlay = recording => {
    if (currentAudio) {
      currentAudio.pause()
    }
    const audio = new Audio(recording.url)
    audio.play()
    setCurrentAudio(audio)
  }

  const handlePause = () => {
    if (currentAudio) {
      currentAudio.pause()
      setCurrentAudio(null)
    }
  }

  const handleBulkDelete = async () => {
    if (selectedRowKeys.length === 0) {
      message.warning('No files selected for deletion.')
      return
    }

    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${selectedRowKeys.length} file(s)?`
    )
    if (confirmDelete) {
      setIsBulkDeleting(true)
      let successCount = 0
      let failCount = 0

      for (const fileId of selectedRowKeys) {
        if (fileId) {
          // Ensure the id is not undefined
          try {
            console.log(`Attempting to delete recording with ID: ${fileId}`)
            const result = await deleteRecording(fileId)
            if (result.success) {
              successCount++
              console.log(`Successfully deleted recording with ID: ${fileId}`)
            } else {
              failCount++
              console.error(
                `Failed to delete recording (ID: ${fileId}):`,
                result.error
              )
            }
          } catch (error) {
            failCount++
            console.error(`Error deleting recording (ID: ${fileId}):`, error)
          }
        } else {
          console.error('Encountered undefined recording ID during bulk delete')
          failCount++
        }
      }

      await fetchData() // Refresh the data after deletions
      setSelectedRowKeys([]) // Clear selection
      setIsBulkDeleting(false)

      if (successCount > 0) {
        message.success(`Successfully deleted ${successCount} file(s).`)
      }
      if (failCount > 0) {
        message.error(
          `Failed to delete ${failCount} file(s). Please try again.`
        )
      }
    }
  }

  useEffect(() => {
    const defaultColumns = [
      {
        title: (
          <span onClick={() => handleSort('name')} style={{ fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>
            Name {renderSortIcon('name')}
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        width: 300,
        render: (text, record) => (
          <div
            style={{
              width: '100%', // Ensure the div takes up full width
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden' // This is necessary for ellipsis to work
            }}
            className='recording-name'
            onClick={() => openPlayerScreen(record.id)}
          >
            {record.type === 'audio' ? (
              <div className='icon-container audio'>
                <MdAudiotrack className='play-icon' />
              </div>
            ) : record.type === 'video' ? (
              <div className='icon-container video'>
                <FaVideo className='play-icon' fontSize='small' />
              </div>
            ) : record.type === 'screen' ? (
              <div className='icon-container screen'>
                <DesktopWindowsIcon className='play-icon' fontSize='small' />
              </div>
            ) : record.type === 'meeting' ? (
              <div className='icon-container meeting'>
                <GroupsIcon className='play-icon' fontSize='small' />
              </div>
            ) : (
              <></>
            )}
            <span style={{ fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>{text}</span>
          </div>
        )
      },
      {
        title: (
          <span onClick={() => handleSort('duration')} style={{ fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>
            Duration {renderSortIcon('duration')}
          </span>
        ),
        dataIndex: 'duration',
        key: 'duration',
        sorter: true,
        width: 100,
        render: text => (
          <span style={{ color: '#7e858c', fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>{formatDuration(text)}</span>
        )
      },
      {
        title: (
          <span onClick={() => handleSort('created_at')} style={{ fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>
            Date Created {renderSortIcon('created_at')}
          </span>
        ),
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        width: 180,
        render: text => (
          <span style={{ color: '#7e858c', fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>
            {new Date(text).toLocaleString()}
          </span>
        )
      },
      {
        title: (
          <span onClick={() => handleSort('updated_at')} style={{ fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>
            Last Modified {renderSortIcon('updated_at')}
          </span>
        ),
        dataIndex: 'updated_at',
        key: 'updated_at',
        sorter: true,
        width: 180,
        render: text => (
          <span style={{ color: '#7e858c', fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>
            {new Date(text).toLocaleString()}
          </span>
        )
      },
      {
        title: (
          <span onClick={() => handleSort('user_id')} style={{ fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>
            Creator {renderSortIcon('user_id')}
          </span>
        ),
        dataIndex: 'user_id',
        key: 'user_id',
        sorter: true,
        width: 100,
        render: text => (
          <span style={{ color: '#7e858c', fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>
            {text === user.id ? user.name : 'Unknown'}
          </span>
        )
      },
      {
        title: (<span style={{ fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>Actions</span>),
        key: 'actions',
        width: 100,
        render: (_, record) => (
          <>
            <Button
              style={{ margin: 0, color: '#04a3ff' }}
              type='link'
              icon={<EditOutlined color='#04a3ff' />}
              onClick={e => {
                e.stopPropagation()
                const newName = prompt('Enter new file name:', record.name)
                if (newName) handleRenameItem(record.id, newName, false)
              }}
            />
            <Button
              type='link'
              danger
              icon={<DeleteOutlined />}
              onClick={e => {
                e.stopPropagation()
                handleDeleteFile(record.id)
              }}
            />
          </>
        )
      }
    ]

    setColumns(defaultColumns)
  }, [
    user.id,
    sortColumn,
    sortDirection,
    handleSort,
    openPlayerScreen,
    renderSortIcon,
    user.name
  ])

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: column => ({
      width: column.width,
      onResize: handleResize(index)
    })
  }))

  // const renderMobileCard = record => (
  //   <Card
  //     key={record.id}
  //     className='mobile-file-card'
  //     onClick={() => openPlayerScreen(record.id)}
  //   >
  //     <div className='mobile-file-card-header'>
  //       <PlayCircleOutlined className='play-icon' />
  //       <span className='mobile-file-name'>{record.name}</span>
  //     </div>
  //     <div className='mobile-file-card-content'>
  //       <p>
  //         <ClockCircleOutlined /> Duration: {formatDuration(record.duration)}
  //       </p>
  //       <p>
  //         <CalendarOutlined /> Created:{' '}
  //         {new Date(record.created_at).toLocaleString()}
  //       </p>
  //     </div>
  //     <div className='mobile-file-card-actions'>
  //       <Button
  //         type='primary'
  //         icon={<EditOutlined />}
  //         onClick={e => {
  //           e.stopPropagation()
  //           const newName = prompt('Enter new file name:', record.name)
  //           if (newName) handleRenameItem(record.id, newName, false)
  //         }}
  //       >
  //         Rename
  //       </Button>
  //       <Button
  //         type='primary'
  //         danger
  //         icon={<DeleteOutlined />}
  //         onClick={e => {
  //           e.stopPropagation()
  //           handleDeleteFile(record.id)
  //         }}
  //       >
  //         Delete
  //       </Button>
  //     </div>
  //   </Card>
  // )

  if (isLoading) {
    return <div className='loading-message'>Loading...</div>
  }

  return (
    <div className={`file-management-container ${isDarkMode ? 'dark' : ''}`}>
      <h2 className='file-management-title'>File Management</h2>
      {error && <p className='error-message'>{error}</p>}
      <div className='create-folder-form'>
        <form onSubmit={handleCreateFolder} className='form'>
          <Input
            value={newFolderName}
            onChange={e => setNewFolderName(e.target.value)}
            placeholder='New folder name'
            className='folder-input'
          />
          <Button
            style={{
              backgroundColor: 'var(--primary-color)',
              color: 'var(--text-color)',
              border: 'none',
              boxShadow: 'none',
              fontFamily: '"Roboto", "Helvetica Neue", sans-serif'
            }}
            type='primary'
            icon={<PlusOutlined />}
            htmlType='submit'
          >
            Create Folder
          </Button>
        </form>
      </div>
      <div className='file-management-content'>
        <div className='folders-section'>
          <h3 className='section-title'>Folders</h3>
          <ul className='folders-list'>
            <li
              key='all-files'
              className={`folder-item ${
                currentFolder === null ? 'active-folder' : ''
              }`}
              onClick={() => setCurrentFolder(null)}
            >
              <FolderOutlined className='folder-icon' />{' '}
              <span className='folder-name'>All Files</span>
            </li>
            {fileStructure.folders.map(folder => (
              <li
                key={folder.id}
                className={`folder-item ${
                  currentFolder === folder.id ? 'active-folder' : ''
                }`}
                onClick={() => setCurrentFolder(folder.id)}
              >
                <FolderOutlined className='folder-icon' />{' '}
                <span className='folder-name'>{folder.name}</span>
              </li>
            ))}
          </ul>
        </div>
        <section className='recordings-section'>
          <div className='recordings-header'>
            <Title level={4} style={{ color: 'var(--text-color)', fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>
              Files
            </Title>
            <Button
              style={
                selectedRowKeys.length > 0
                  ? {
                      backgroundColor: '#e12115',
                      color: 'white',
                      border: 'none',
                      fontFamily: '"Roboto", "Helvetica Neue", sans-serif'
                    }
                  : {fontFamily: '"Roboto", "Helvetica Neue", sans-serif'}
              }
              type='primary'
              danger
              onClick={handleBulkDelete}
              disabled={selectedRowKeys.length === 0}
              loading={isBulkDeleting}
            >
              Delete Selected ({selectedRowKeys.length})
            </Button>
          </div>
          {showFileUpload && (
            <div className='file-upload-container'>
              <FileUpload onUpload={handleFileUpload} />
            </div>
          )}
          <div className='recordings-table-container'>
            {isMobile ? (
              <div>
                <List
                  style={isMobile ? { display: 'block', fontFamily: '"Roboto", "Helvetica Neue", sans-serif' } : {fontFamily: '"Roboto", "Helvetica Neue", sans-serif'}}
                  grid={{
                    gutter: 16,
                    column: isMobile ? 1 : 3 // 1 column on mobile, 3 on larger screens
                  }}
                  dataSource={sortedRecordings()}
                  renderItem={recording => (
                    <List.Item>
                      <RecordingCard
                        recording={recording}
                        onEdit={(id, newName) =>
                          handleRenameItem(id, newName, false)
                        }
                        onDelete={handleDeleteFile}
                        onPlay={openPlayerScreen}
                        isDeleting={deletingRecordingId === recording.id}
                      />
                    </List.Item>
                  )}
                />
              </div>
            ) : (
              <Table
                rowSelection={rowSelection}
                columns={resizableColumns}
                dataSource={sortedRecordings()}
                rowKey='id'
                pagination={{ pageSize: 10, fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}
                onRow={record => ({
                  onClick: () => openPlayerScreen(record.id)
                })}
                scroll={{ y: 'calc(100vh - 300px)' }}
                rowClassName={() => 'custom-row-bg'}
              />
            )}
          </div>
        </section>
      </div>
    </div>
  )
}

export default FileManagement
