import React, { useState } from 'react'
import '../styles/Layout.css'
import { useDarkMode } from '../App'
import Sidebar from './Sidebar'
import ChatFooter from './ui/Footer'

const Layout = ({
  children,
  user,
  onSignOut,
  onRecordScreen,
  onShowAudioRecording,
  onImportFiles,
  onTranscribeMeeting,
  onRecordVideo
}) => {
  const { isDarkMode } = useDarkMode()
  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth > 768)

  const handleSidebarToggle = isOpen => {
    setSidebarOpen(isOpen)
  }

  return (
    <div
      className={`app-layout ${isDarkMode ? 'dark' : ''} ${
        sidebarOpen ? 'sidebar-open' : ''
      }`}
    >
      <Sidebar
        noheader={true}
        user={user}
        onSignOut={onSignOut}
        onRecordScreen={onRecordScreen}
        onShowAudioRecording={onShowAudioRecording}
        onImportFiles={onImportFiles}
        onTranscribeMeeting={onTranscribeMeeting}
        onRecordVideo={onRecordVideo}
        onSidebarToggle={handleSidebarToggle}
      />
      <div className='app-main'>{children}</div>
      <ChatFooter />
    </div>
  )
}

export default Layout
