// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Mobile-only footer */
.mobile-footer {
  display: none; /* Hidden by default */
  position: fixed;
  bottom: .8rem;
  left: 0;
  right: 0;
  height: 60px;
  background-color: var(--sidebar-bg-color); /* Customize background color */
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

@media (max-width: 768px) {
  .mobile-footer {
    display: none; /* Hide on chat pages */
  }

  /* Only show footer on non-chat pages */
  body:not([data-page="chat"]) .mobile-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    padding-bottom: env(safe-area-inset-bottom, 0px);
  }
}

`, "",{"version":3,"sources":["webpack://./src/components/ui/Footer/index.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;EACE,aAAa,EAAE,sBAAsB;EACrC,eAAe;EACf,aAAa;EACb,OAAO;EACP,QAAQ;EACR,YAAY;EACZ,yCAAyC,EAAE,+BAA+B;EAC1E,yCAAyC;EACzC,YAAY;AACd;;AAEA;EACE;IACE,aAAa,EAAE,uBAAuB;EACxC;;EAEA,uCAAuC;EACvC;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,gDAAgD;EAClD;AACF","sourcesContent":["/* Mobile-only footer */\n.mobile-footer {\n  display: none; /* Hidden by default */\n  position: fixed;\n  bottom: .8rem;\n  left: 0;\n  right: 0;\n  height: 60px;\n  background-color: var(--sidebar-bg-color); /* Customize background color */\n  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);\n  z-index: 100;\n}\n\n@media (max-width: 768px) {\n  .mobile-footer {\n    display: none; /* Hide on chat pages */\n  }\n\n  /* Only show footer on non-chat pages */\n  body:not([data-page=\"chat\"]) .mobile-footer {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 0 5px;\n    padding-bottom: env(safe-area-inset-bottom, 0px);\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
