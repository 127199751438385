import { supabase } from '../supabaseClient';

export const loadChatSessions = async (userId) => {
  if (!userId) {
    console.error('No user ID provided to loadChatSessions');
    return [];
  }

  try {
    console.log('Loading chat sessions for user:', userId);
    const { data, error } = await supabase
      .from('chat_sessions')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error loading chat sessions:', error);
      throw error;
    }
    
    // Return empty array if no data
    if (!data) {
      console.log('No chat sessions found');
      return [];
    }
    
    console.log('Loaded chat sessions:', {
      count: data.length,
      sessions: data.map(s => ({
        id: s.session_id,
        title: s.title,
        messageCount: s.messages?.length
      }))
    });
    
    return data;
  } catch (error) {
    console.error('Error loading chat sessions:', error);
    // Return empty array instead of throwing
    return [];
  }
};

export const saveChatSession = async (userId, sessionId, title, messages) => {
  try {
    console.log('Saving chat session:', { userId, sessionId, title });
    const { data, error } = await supabase
      .from('chat_sessions')
      .upsert({
        user_id: userId,
        session_id: sessionId,
        title,
        messages,
        updated_at: new Date().toISOString()
      }, {
        onConflict: 'session_id',
        returning: 'minimal'
      });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error saving chat session:', error);
    throw error;
  }
};

export const deleteChatSession = async (sessionId) => {
  try {
    const { error } = await supabase
      .from('chat_sessions')
      .delete()
      .eq('session_id', sessionId);

    if (error) throw error;
  } catch (error) {
    console.error('Error deleting chat session:', error);
    throw error;
  }
}; 