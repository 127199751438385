import React, { useState } from 'react'
import '../index.css'
import { Button, message } from 'antd'
import axios from 'axios'
import { useAuth } from '../../../AuthContext'
import PrimaryButton from '../../ui/Buttons/primaryButton'
import SecondaryButton from '../../ui/Buttons/secondaryButton'
import { useNavigate } from 'react-router-dom'

// Dynamic Subscription Card Component
export const SubscriptionCard = ({
  title,
  price,
  duration,
  description,
  benefits,
  price_id,
  hosted_payment_link,
  user,
  isSubscribed,
  onSubscriptionUpdate
}) => {
  const [isCancelling, setIsCancelling] = useState(false)
  const navigate = useNavigate()

  const handleCancelSubscription = async () => {
    if (!user) return

    try {
      setIsCancelling(true) // Set loading state to true
      const response = await axios.post(
        // "http://localhost:5000/stripe/cancel-subscription",
        'https://solo-recall.vercel.app/stripe/cancel-subscription',
        {
          userId: user.id
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      if (response.status === 200) {
        message.success('Subscription canceled successfully!')

        if (onSubscriptionUpdate) {
          onSubscriptionUpdate()
        }
      } else {
        message.error('Failed to cancel subscription. Please try again.')
      }
    } catch (error) {
      console.error('Error during subscription cancellation:', error)
      message.error('An error occurred while canceling your subscription.')
    } finally {
      setIsCancelling(false) // Reset loading state
    }
  }

  return (
    user && (
      <div className='subscription-card'>
        <h3 className='plan-title'>{title}</h3>
        <p className='price'>
          {price} <span className='duration'>/{duration}</span>
        </p>

        <p className='description'>{description}</p>

        {/* Benefits list commented out */}
        {/* <ul className='benefits-list'>
          {benefits.map((benefit, index) => (
            <li key={index}>{benefit}</li>
          ))}
        </ul> */}

        {isSubscribed ? (
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
          >
            <Button className='subscribed-btn' type='primary' disabled>
              Subscribed
            </Button>
            <Button
              className='subscribed-btn'
              type='secondary'
              onClick={handleCancelSubscription}
              loading={isCancelling}
            >
              Cancel Subscription
            </Button>
            <Button
              className='subscribtions-view-btn'
              onClick={() => navigate('/my_subscriptions')}
            >
              My Subscriptions
            </Button>
          </div>
        ) : (
          <Button
            className='buy-now-btn'
            href={`${hosted_payment_link}?prefilled_email=${user?.email}&client_reference_id=${user?.id}`}
            target='_blank'
          >
            Buy Now
          </Button>
        )}
      </div>
    )
  )
}
