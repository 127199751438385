import React from 'react';
import { Button as AntdButton } from 'antd';
import './index.css'; 

const SecondaryButton = ({
  shape = 'default', 
  size = 'middle',
  loading = false,
  disabled = false,
  onClick,
  children,
  ...rest
}) => {
  return (
    <AntdButton
      className="secondary-button"
      shape={shape}
      size={size}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      {...rest} // spread remaining props
    >
      {children}
    </AntdButton>
  );
};

export default SecondaryButton;
