import React, { useState } from 'react'
import { Input, Tooltip } from 'antd'
import './index.css'
import AudioRecorder from '../AudioRecorder'
import PrimaryButton from '../ui/Buttons/primaryButton'
import DefaultButton from '../ui/Buttons/defaultButton'
import { FaArrowUp } from 'react-icons/fa'
import { FaFileAudio } from 'react-icons/fa6'
import { FaMicrophone } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { RiRobot2Line } from 'react-icons/ri'
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import { useDarkMode } from '../../App'
import logo from '../../assets/images/logo.png'
import { getCurrentSubscription } from '../../DatabaseService'

const ChatComponent = () => {
  const [messages, setMessages] = useState([
    { text: 'Hello! How can I help you today?', sender: 'bot' }
    // { text: 'I have a question about my order.', sender: 'user' },
  ])
  const [newMessage, setNewMessage] = useState('')
  const navigate = useNavigate()
  const { isDarkMode } = useDarkMode()

  const handleSendMessage = () => {
    if (newMessage.trim() === '') return
    // Redirect to chat screen with message as URL param
    navigate(`/ai-chat?message=${encodeURIComponent(newMessage)}`)
    setNewMessage('') // Clear input
  }

  const handleInputKeyPress = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSendMessage()
    }
  }

  return (
    <div className='chat-outer-container'>
      <div className='quick-chat-container'>
        {/* <div className='quick-chat-display'>
          {messages.map((message, index) => (
            <div key={index} className={`message-bubble-ai`}>
              <div
                style={{
                  padding: '10px',
                  borderRadius: 999,
                  backgroundColor: 'var(--primary-color)'
                }}
              >
                <img 
                  src={logo} 
                  alt="logo" 
                  style={{ 
                    width: '48px', 
                    height: '48px', 
                    minWidth: '35px', 
                    minHeight: '35px',
                    objectFit: 'cover',  
                    display: 'block'
                  }} 
                />
              </div>
              <p className='chat-message-ai'>{message.text}</p>
            </div>
          ))}
        </div> */}

        <div className='quick-chat-input-container'>
          <Input.TextArea
            placeholder='Hello, how can I help you!'
            value={newMessage}
            onChange={e => setNewMessage(e.target.value)}
            className='quick-chat-input'
            autoSize={{ minRows: 1, maxRows: 6 }}
            onPressEnter={handleInputKeyPress} // Send message on enter
          />
          <div className='chat-input-buttons'>
            <DefaultButton shape='circle'>
              <Tooltip title={'Record audio'}>
                <FaMicrophone />
              </Tooltip>
            </DefaultButton>
            <AudioRecorder />

            <PrimaryButton
              shape='circle'
              icon={<FaArrowUp />}
              onClick={handleSendMessage}
              disabled={newMessage?.trim() === ''}
              // className="chat-input-button"
              // style={{margin:0}}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatComponent
