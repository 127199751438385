import React, { useState } from 'react'
import { Input, Tooltip } from 'antd'
import AudioRecorder from '../../AudioRecorder'
import PrimaryButton from '../Buttons/primaryButton'
import DefaultButton from '../Buttons/defaultButton'
import { LuFileAudio } from 'react-icons/lu'
import { FaArrowUp } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import { FaFileAudio } from 'react-icons/fa6'
import { FaMicrophone } from 'react-icons/fa'
import './index.css'
import { useNavigate } from 'react-router-dom'

const ChatFooter = () => {
  const location = useLocation()
  const [newMessage, setNewMessage] = useState('')
  const navigate = useNavigate()

  const handleSendMessage = () => {
    if (newMessage.trim() === '') return
    // Redirect to chat screen with message as URL param
    navigate(`/ai-chat?message=${encodeURIComponent(newMessage)}`)
    setNewMessage('') // Clear input
  }

  const isChatOrSettingsPage =
    location.pathname === '/ai-chat' || location.pathname === '/settings'

  return (
    <>
      {/* Main chat input container in the footer */}
      {!isChatOrSettingsPage && (
        <div className='mobile-footer'>
          <div className='main-chat-input-container'>
            <Input.TextArea
              placeholder='Type your message...'
              // value={input}
              // onChange={e => setInput(e.target.value)}
              className='chat-input'
              // disabled={isLoading}
              autoSize={{ minRows: 1, maxRows: 6 }}
              // onPressEnter={e => {
              //   e.preventDefault();
              //   if (input.trim() !== '') sendMessage();
              // }}
            />
            <div className='chat-input-buttons'>
              <DefaultButton shape='circle'>
                <Tooltip title={'Transcribe'}>
                  <FaMicrophone />
                </Tooltip>
              </DefaultButton>
              <AudioRecorder />
              <PrimaryButton
                shape='circle'
                icon={<FaArrowUp />}
                onClick={handleSendMessage}
                // disabled={isLoading || input.trim() === ''}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ChatFooter
