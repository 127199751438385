import { supabase } from '../supabaseClient';

export const generateSummary = async (transcriptionText, recordingId, userId) => {
  // ... implementation
};

export const fetchSummary = async (recordingId) => {
  // ... implementation
};

export const handleSummaryGeneration = async (recordingId, userId) => {
  // ... implementation
}; 