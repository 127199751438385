import React from 'react';
import { Button } from 'antd';

const DefaultButton = React.forwardRef((props, ref) => {
  const { children, ...rest } = props;
  return (
    <Button ref={ref} {...rest}>
      {children}
    </Button>
  );
});

export default DefaultButton;
