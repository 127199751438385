import React from "react";
import { Checkbox } from "antd";
import "./index.css";

export default function DashboardRecents({
  heading,
  data,
  name,
  duration,
  time,
}) {
  return (
    <div className="recents-container">
      <h3 className="recording-heading">{heading}</h3>
      {data.map((item, index) => (
        <div
          key={index}
          className="recording-card"
          style={{
            gridTemplateColumns: duration ? "30px 1fr 1fr 1fr" : "30px 2fr 1fr",
          }}
        >
          <div className="hover-checkbox">
            <Checkbox />
          </div>
          {name && <p className="recents-card-text">{item.name}</p>}
          {duration && <p className="recents-card-text">{item.duration}</p>}
          {time && <p className="recents-card-text">{item.time}</p>}
        </div>
      ))}
    </div>
  );
}
