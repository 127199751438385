import React, { useState, useEffect, useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useAuth } from '../AuthContext'
import { supabase } from '../supabaseClient'
import { saveChatSession, loadChatSessions, deleteChatSession } from '../services/ChatService'
import { getCurrentSubscription } from '../services/SubscriptionService'
import ChatList from './ChatList'
import MainChat from './MainChatComponent'
import AntDrawer from './Drawer'
import { useSearchParams } from 'react-router-dom'
import { chat, streamChat, AVAILABLE_MODELS } from '../langchain'
import { useTTS } from '../hooks/useTTS'
import { message } from 'antd'
import { getValidApiKeys } from '../DatabaseService'
import { UnorderedListOutlined } from '@ant-design/icons'
import { useSettings } from '../contexts/SettingsContext'
import UserSettingsService from '../services/UserSettingsService'

const AIChat = () => {
  const [searchParams] = useSearchParams()
  const [initialMessage, setInitialMessage] = useState(null)
  const [input, setInput] = useState(initialMessage || '')
  const { user } = useAuth()
  const [selectedLLM, setSelectedLLM] = useState('openai')
  const [defaultModel, setDefaultModel] = useState('gpt-3.5-turbo')
  const [sessions, setSessions] = useState([])
  const [currentSessionId, setCurrentSessionId] = useState(null)
  const [openRouterModels, setOpenRouterModels] = useState([])
  const [isHovered, setIsHovered] = useState(false)
  const [currentSubscription, setCurrentSubscription] = useState(null)
  const [ttsProvider, setTTSProvider] = useState(
    localStorage.getItem('ttsProvider') || 'elevenlabs'
  )
  const [readAloud, setReadAloud] = useState(
    localStorage.getItem('readAloud') === 'true'
  )
  const [selectedVoice, setSelectedVoice] = useState(
    localStorage.getItem('selectedVoice') || ''
  )
  const [isPlaying, setIsPlaying] = useState(false)
  const [actualKey, setActualKey] = useState(null)
  const [apiKeys, setApiKeys] = useState({})
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [messages, setMessages] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const settingsContext = useSettings();
  const { settings, setSettings } = settingsContext || {};
  const tts = useTTS();

  const handleMouseEnter = () => {
    if (!isSidebarOpen) {
      setIsHovered(true);
      setIsSidebarOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (isHovered) {
      setIsHovered(false);
      setIsSidebarOpen(false);
    }
  };

  const handleOpenSidebar = useCallback(() => {
    setIsSidebarOpen(true)
  }, [])

  const handleCloseSidebar = useCallback(() => {
    setIsSidebarOpen(false)
  }, [])

  const handleNewChat = useCallback(() => {
    setMessages([])
    setCurrentSessionId(null)
  }, [])

  const handleSetInput = value => {
    setInput(value)
  }

  const fetchKeys = async () => {
    try {
      const validKeys = await getValidApiKeys();
      if (!Array.isArray(validKeys)) {
        console.error('Invalid response from getValidApiKeys:', validKeys);
        return [];
      }

      const formattedKeys = validKeys
        .filter(key => key && key.apiKey && key.name) 
        .map(key => ({
          apiKey: key.apiKey,
          name: key.name,
          appKey: key.appKey
        }));

      console.log('Fetched API keys:', formattedKeys.length);
      return formattedKeys;
    } catch (error) {
      console.error('Error fetching keys:', error);
      return []; 
    }
  };

  useEffect(() => {
    const loadKeys = async () => {
      try {
        const keys = await fetchKeys();
        if (keys && keys.length > 0) {
          setApiKeys(keys);
        } else {
          console.log('No valid API keys found');
        }
      } catch (error) {
        console.error('Error loading API keys:', error);
      }
    };

    loadKeys();
  }, []);

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const savedSettings = await UserSettingsService.getSettings();
        if (setSettings && typeof setSettings === 'function') {
          setSettings(savedSettings);
        }
        
        // Update local state regardless of global settings
        setSelectedLLM(savedSettings.selectedLLM || 'openai');
        setDefaultModel(savedSettings.defaultModel || 'gpt-3.5-turbo');
        setReadAloud(savedSettings.readAloud || false);
        setTTSProvider(savedSettings.ttsProvider || 'elevenlabs');
        setSelectedVoice(savedSettings.selectedVoice || '21m00Tcm4TlvDq8ikWAM');
        
        console.log('Loading saved settings - LLM:', savedSettings.selectedLLM, 'Model:', savedSettings.defaultModel);
      } catch (error) {
        console.error('Error loading settings:', error);
        const defaultSettings = {
          selectedLLM: 'openai',
          defaultModel: 'gpt-3.5-turbo',
          readAloud: false,
          ttsProvider: 'elevenlabs',
          selectedVoice: '21m00Tcm4TlvDq8ikWAM'
        };
        
        if (setSettings && typeof setSettings === 'function') {
          setSettings(defaultSettings);
        }
        
        // Update local state with defaults
        setSelectedLLM(defaultSettings.selectedLLM);
        setDefaultModel(defaultSettings.defaultModel);
        setReadAloud(defaultSettings.readAloud);
        setTTSProvider(defaultSettings.ttsProvider);
        setSelectedVoice(defaultSettings.selectedVoice);
      }
    };

    loadSettings();
  }, [setSettings]); // Only depend on setSettings

  useEffect(() => {
    const fetchModels = async () => {
      if (selectedLLM === 'openrouter') {
        try {
          const apiKeys = JSON.parse(localStorage.getItem('apiKeys')) || {}
          const apiKey = apiKeys.openrouter
          if (apiKey) {
            const models = AVAILABLE_MODELS[selectedLLM] || []
            setOpenRouterModels(models)
          }
        } catch (error) {
          console.error('Error fetching OpenRouter models:', error)
        }
      }
    }

    fetchModels()
  }, [selectedLLM])

  const selectSession = sessionId => {
    const session = sessions.find(s => s.session_id === sessionId)

    if (session) {
      setCurrentSessionId(sessionId)
      setMessages(session.messages)
    }
  }

  const deleteSession = async sessionId => {
    try {
      await deleteChatSession(sessionId)
      setSessions(sessions.filter(s => s.session_id !== sessionId))
      if (currentSessionId === sessionId) {
        setCurrentSessionId(null)
        setMessages([])
      }
    } catch (error) {
      console.error('Error deleting chat session:', error)
    }
  }

  const generateTitle = async messages => {
    try {
      const prompt = `Generate an extremely concise title (maximum 3-4 words) that captures the main topic of this conversation. Do not use punctuation or quotes. Here's the conversation:\n\n${messages
        .slice(0, 2) 
        .map(m => `${m.sender}: ${m.text}`)
        .join('\n')}`
      
      const apiKeys = JSON.parse(localStorage.getItem('apiKeys')) || {}
      const apiKey = apiKeys[selectedLLM]
      const title = await chat(
        prompt,
        selectedLLM,
        defaultModel,
        apiKey,
        !!currentSubscription
      )
      return title.replace(/['",.]/g, '').trim().split(' ').slice(0, 4).join(' ')
    } catch (error) {
      console.error('Error generating title:', error)
      return 'New Chat'
    }
  }

  const handleTTSPlayback = async (text, isPausing) => {
    if (!settings.readAloud) return;

    try {
      if (isPausing) {
        tts.pausePlayback();
        setIsPlaying(false);
      } else if (tts.isPaused) {
        tts.resumePlayback();
        setIsPlaying(true);
      } else {
        setIsPlaying(true);
        await tts.convertText(text, settings.selectedVoice, apiKeys.elevenlabs);
        setIsPlaying(false);
      }
    } catch (error) {
      console.error('TTS Error:', error);
      message.error('Failed to convert text to speech. Please check your settings.');
      setIsPlaying(false);
    }
  };

  const sendMessage = async () => {
    if (!input.trim()) return;
    setIsLoading(true);

    try {
      const apiKeys = JSON.parse(localStorage.getItem('apiKeys')) || {}
      let apiKey = apiKeys[selectedLLM]

      if (!apiKey && !currentSubscription?.length) {
        throw new Error(
          `No API key found for ${selectedLLM}. Please add it in the settings or subscribe to one of the plans`
        )
      }
      if (currentSubscription?.length < 1 && actualKey === null) {
        throw new Error(
          `API key found for ${selectedLLM} is wrong. Please add correct one in the settings or subscribe to one of the plans`
        )
      }
      if (currentSubscription?.length < 1 && actualKey !== null) {
        apiKey = actualKey
      }
      if (currentSubscription?.length > 0) {
        apiKey = actualKey
      }

      const userMessage = { text: input, sender: 'user' };
      const newMessages = [...messages, userMessage];
      setMessages(newMessages);
      setInput('');
      handleSetInput('');

      const response = await chat(
        input,
        selectedLLM,
        defaultModel,
        apiKey,
        !!currentSubscription
      );

      const aiMessage = { text: response, sender: 'ai' };
      const updatedMessages = [...newMessages, aiMessage];
      setMessages(updatedMessages);

      // Save chat session
      try {
        const title = await generateTitle(updatedMessages);
        const sessionId = currentSessionId || uuidv4();
        
        console.log('Saving chat session:', {
          userId: user.id,
          sessionId,
          messageCount: updatedMessages.length
        });

        await saveChatSession(user.id, sessionId, title, updatedMessages);

        if (!currentSessionId) {
          // New session
          const newSession = {
            session_id: sessionId,
            title,
            messages: updatedMessages,
            user_id: user.id,
            created_at: new Date().toISOString()
          };
          
          setCurrentSessionId(sessionId);
          setSessions(prevSessions => [newSession, ...prevSessions]);
        } else {
          // Update existing session
          setSessions(prevSessions => 
            prevSessions.map(session => 
              session.session_id === sessionId 
                ? { ...session, title, messages: updatedMessages }
                : session
            )
          );
        }
      } catch (error) {
        console.error('Error saving chat session:', error);
        message.error('Failed to save chat session');
      }
    } catch (error) {
      console.error('Error in sendMessage:', error);
      setMessages(prev => [
        ...prev,
        { text: input, sender: 'user' },
        { text: `Error: ${error.message}`, sender: 'ai' }
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModelChange = value => {
    setDefaultModel(value)
    localStorage.setItem('defaultModel', value)
  }

  const getCurrentUserSubscription = async user => {
    try {
      const result = await getCurrentSubscription(user.id)
      setCurrentSubscription(result)
    } catch (error) {
      console.error('Error fetching subscription:', error)
    }
  }

  useEffect(() => {
    if (user) {
      getCurrentUserSubscription(user)
    }
  }, [user])

  const createNewSession = () => {
    setCurrentSessionId(null)
    setMessages([])
  }

  useEffect(() => {
    const loadInitialChatHistory = async () => {
      if (!user) return;
      
      try {
        console.log('Loading chat history for user:', user.id);
        const chatSessions = await loadChatSessions(user.id);
        console.log('Loaded chat sessions:', chatSessions);
        
        if (Array.isArray(chatSessions)) {
          setSessions(chatSessions);
          
          // If there's a current session, load its messages
          if (currentSessionId) {
            const currentSession = chatSessions.find(s => s.session_id === currentSessionId);
            if (currentSession) {
              setMessages(currentSession.messages || []);
            }
          }
        } else {
          console.error('Invalid chat sessions data:', chatSessions);
          setSessions([]);
        }
      } catch (error) {
        console.error('Error loading chat history:', error);
        message.error('Failed to load chat history');
        setSessions([]);
      }
    };

    loadInitialChatHistory();
  }, [user, currentSessionId]); // Add currentSessionId as dependency

  return (
    <div className="ai-chat">
      <div className={`ai-chat-container ${isSidebarOpen ? 'sidebar-open' : ''}`}>
        <MainChat 
          messages={messages}
          setMessages={setMessages}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          onSoloRecallClick={handleOpenSidebar}
          input={input}
          setInput={handleSetInput}
          sendMessage={sendMessage}
          selectedLLM={selectedLLM}
          defaultModel={defaultModel}
          onModelChange={handleModelChange}
          openRouterModels={openRouterModels}
          onOpenSidebar={handleOpenSidebar}
          playTTSAudio={handleTTSPlayback}
          isPlaying={isPlaying}
          drawerOpen={isSidebarOpen}
        />
        <AntDrawer
          open={isSidebarOpen}
          onClose={handleCloseSidebar}
          onAddNew={handleNewChat}
        >
          <ChatList
            sessions={sessions}
            currentSessionId={currentSessionId}
            onSelectSession={selectSession}
            onDeleteSession={deleteSession}
            onNewSession={handleNewChat}
          />
        </AntDrawer>
      </div>
    </div>
  )
}

export default React.memo(AIChat)
