import React from 'react'
import { Drawer } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import './styles.css'

const AntDrawer = ({ children, open, onClose }) => {
  return (
    <Drawer
      onClose={onClose}
      open={open}
      width={300}
      title='Chat History'
      mask={false}
      zIndex={1000}
      placement="right"
      transitionTime={530}
      style={{
        position: 'absolute',
        backgroundColor: 'var(--bg-color)',
        color: 'var(--text-color)',
        fontFamily: '"Roboto", "Helvetica Neue", sans-serif'
      }}
      closeIcon={<CloseOutlined style={{ color: 'var(--text-color)' }} />}
    >
      {children}
    </Drawer>
  )
}

export default AntDrawer
