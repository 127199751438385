import React, { createContext, useContext, useState, useCallback } from 'react';
import UserSettingsService from '../services/UserSettingsService';

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({
    selectedLLM: 'openai',
    defaultModel: 'gpt-3.5-turbo',
    readAloud: false,
    ttsProvider: 'elevenlabs',
    selectedVoice: '21m00Tcm4TlvDq8ikWAM',
    conversationSettings: {
      isEnabled: false,
      selectedVoice: null,
      stability: 0.5,
      similarity: 0.75,
      style: 'balanced'
    }
  });

  // Add updateSettings method to handle settings updates
  const updateSettings = useCallback(async (newSettings) => {
    try {
      // Merge existing settings with new settings
      const updatedSettings = {
        ...settings,
        ...newSettings,
        // Handle nested conversationSettings properly
        conversationSettings: {
          ...settings.conversationSettings,
          ...(newSettings.conversationSettings || {})
        }
      };

      // Save to database
      await UserSettingsService.saveSettings(updatedSettings);
      
      // Update local state
      setSettings(updatedSettings);
      
      return updatedSettings;
    } catch (error) {
      console.error('Error updating settings:', error);
      throw error;
    }
  }, [settings]);

  // Add method specifically for updating conversation settings
  const updateConversationSettings = useCallback(async (conversationSettings) => {
    try {
      const updatedSettings = {
        ...settings,
        conversationSettings: {
          ...settings.conversationSettings,
          ...conversationSettings
        }
      };

      // Save to database
      await UserSettingsService.saveSettings(updatedSettings);
      
      // Update local state
      setSettings(updatedSettings);
      
      return updatedSettings;
    } catch (error) {
      console.error('Error updating conversation settings:', error);
      throw error;
    }
  }, [settings]);

  return (
    <SettingsContext.Provider value={{ 
      settings, 
      setSettings,
      updateSettings,
      updateConversationSettings
    }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};

export default SettingsContext; 