import { useState, useCallback, useRef } from 'react';
import { Conversation } from '@11labs/client';
import UserSettingsService from '../services/UserSettingsService';

export const useElevenLabsConversation = ({ onMessageReceived }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isAiSpeaking, setIsAiSpeaking] = useState(false);
  const [error, setError] = useState(null);
  const conversationRef = useRef(null);
  const streamRef = useRef(null);
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);

  const start = useCallback(async () => {
    try {
      if (audioContextRef.current || streamRef.current) {
        console.warn('Audio context or stream already exists, cleaning up first...');
        await stop();
      }

      setIsProcessing(true);
      setError(null);

      const agentId = process.env.REACT_APP_ELEVEN_LABS_AGENT_ID;
      if (!agentId) {
        throw new Error('ElevenLabs Agent ID not found');
      }

      const apiKey = await UserSettingsService.getElevenLabsApiKey();
      if (!apiKey) {
        throw new Error('ElevenLabs API key not found. Please add it in settings.');
      }

      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)({
        sampleRate: 44100,
        latencyHint: 'interactive'
      });

      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          channelCount: 1,
          sampleRate: 44100
        }
      });
      
      streamRef.current = stream;

      sourceNodeRef.current = audioContextRef.current.createMediaStreamSource(stream);
      const analyser = audioContextRef.current.createAnalyser();
      sourceNodeRef.current.connect(analyser);

      const conv = await Conversation.startSession({
        agentId,
        apiKey,
        audioConfig: {
          sampleRate: 44100,
          bitDepth: 16,
          channels: 1
        },
        ttsConfig: {
          stability: 0.5,
          similarity_boost: 0.75,
          style: 0,
          use_speaker_boost: true,
          model_id: 'eleven_turbo_v2'
        },
        onConnect: () => {
          setIsRecording(true);
          setError(null);
        },
        onDisconnect: () => {
          cleanupAudioResources();
          setIsRecording(false);
          setIsAiSpeaking(false);
          conversationRef.current = null;
        },
        onMessage: (message) => {
          if (message.isFinal) {
            onMessageReceived?.(message.text);
          }
        },
        onError: (err) => {
          console.error('Conversation error:', err);
          setError(err.message || 'An error occurred');
          setIsRecording(false);
          setIsAiSpeaking(false);
        },
        onModeChange: (mode) => {
          setIsAiSpeaking(mode.mode === 'speaking');
          setIsRecording(mode.mode === 'listening');
        }
      });

      conversationRef.current = conv;
    } catch (error) {
      console.error('Conversation start error:', error);
      setError(error.message || 'Failed to start conversation');
      cleanupAudioResources();
    } finally {
      setIsProcessing(false);
    }
  }, [onMessageReceived]);

  const cleanupAudioResources = useCallback(() => {
    if (sourceNodeRef.current) {
      sourceNodeRef.current.disconnect();
      sourceNodeRef.current = null;
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
  }, []);

  const stop = useCallback(async () => {
    try {
      if (conversationRef.current) {
        await conversationRef.current.endSession();
        conversationRef.current = null;
      }
      cleanupAudioResources();
      setIsRecording(false);
      setIsAiSpeaking(false);
    } catch (error) {
      setError(error.message || 'Failed to stop conversation');
    }
  }, [cleanupAudioResources]);

  const updateVoice = useCallback(async (voiceId) => {
    try {
      const agentId = process.env.REACT_APP_ELEVEN_LABS_AGENT_ID;
      if (!agentId) {
        throw new Error('ElevenLabs Agent ID not found');
      }

      const apiKey = await UserSettingsService.getElevenLabsApiKey();
      if (!apiKey) {
        throw new Error('ElevenLabs API key not found');
      }

      console.log('Updating agent voice configuration:', { 
        agentId, 
        voiceId,
        hasApiKey: !!apiKey 
      });

      const response = await fetch(`https://api.elevenlabs.io/v1/convai/agents/${agentId}`, {
        method: 'PATCH',
        headers: {
          'xi-api-key': apiKey,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          conversation_config: {
            tts: {
              model_id: 'eleven_turbo_v2',
              voice_id: voiceId,
              stability: 0.5,
              similarity_boost: 0.75,
              optimize_streaming_latency: 0
            }
          }
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('API Response:', {
          status: response.status,
          statusText: response.statusText,
          body: errorText
        });
        throw new Error(`Failed to update voice: ${response.status} ${errorText}`);
      }

      const data = await response.json();
      console.log('Successfully updated agent voice:', data);

      // If we have an active conversation, restart it with the new voice
      if (conversationRef.current) {
        await stop();
        await start();
      }

      return true;
    } catch (error) {
      console.error('Error updating agent voice:', {
        message: error.message,
        stack: error.stack
      });
      throw error;
    }
  }, [start, stop]);

  return {
    start,
    stop,
    updateVoice,
    isProcessing,
    isRecording,
    isAiSpeaking,
    error
  };
}; 