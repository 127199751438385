// AuthContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { supabase } from './Authentication';

const AuthContext = createContext({
  user: null,
  loading: true,
  signIn: async () => {},
  signOut: async () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setUser(session?.user ?? null);
      setLoading(false);
    };

    fetchSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

    return () => {
      if (authListener && typeof authListener.unsubscribe === 'function') {
        authListener.unsubscribe();
      }
    };
  }, []);

  const signIn = async (email, password) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) throw error;
  
      // Extract only the serializable session data
      const session = supabase.auth.getSession();
      console.log('sess ', session)
      session.then(res => {
        if (window.opener) {
          // Send only serializable data
          window.opener.postMessage(
            {
              type: 'AUTH_SUCCESS',
              session: {
                access_token: res.data.session?.access_token,
                refresh_token: res.data.session?.refresh_token,
                user: res.data.session?.user, // This should be serializable
              },
            },
            '*'
          );
        }
    })
      return data;
    } catch (error) {
      console.error('Error signing in:', error);
      throw error;
    }
  };

  // const signIn = async (email, password) => {
  //   try {
  //     const { data, error } = await supabase.auth.signInWithPassword({ email, password });
  //     if (error) throw error;
  //     return data;
  //   } catch (error) {
  //     console.error('Error signing in:', error);
  //     throw error;
  //   }
  // };

  const signOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  };

  const value = {
    signIn,
    signOut,
    user,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
