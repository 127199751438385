import React from "react";
import { Button } from "antd";
import img1 from "../../assets/icons/outlook.png";
import img2 from "./../../assets/icons/calendar.png";
import calendar from "./../../assets/images/calendar2.png";
import "./index.css";

const ConnectApps = () => {
  return (
    <div className="calendar-connect-container">
      <img src={calendar} alt="calendar" className="calendar-image" />
      <div className="calendar-connect-content">
        <div className="calendar-main-text">
          Link your calendar to get your upcoming events
        </div>
        <div className="calendar-secondary-text">
          Sync your schedules effortlessly
        </div>
        <div className="calendar-button-container">
          <Button className="calendar-connect-button" type="default">
            <img src={img2} alt="Google Calendar" className="calendar-icon" />
            <span style={{ fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>Connect Google Calendar</span>
          </Button>
          <Button className="calendar-connect-button" type="default">
            <img src={img1} alt="Microsoft Outlook" className="calendar-icon" />
            <span style={{ fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>Connect Microsoft Outlook</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConnectApps;
