import { fetchOpenRouterModels, getOpenAIModels } from '../langchain';

// AI Model Options
export const MODEL_OPTIONS = {
  // OpenAI models will be fetched dynamically, this is just a fallback
  openai: [
    { value: 'gpt-4-turbo-preview', label: 'GPT-4 Turbo' },
    { value: 'gpt-4', label: 'GPT-4' },
    { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo' }
  ],
  claude: [
    { value: 'claude-3-opus-20240229', label: 'Claude 3 Opus' },
    { value: 'claude-3-sonnet-20240229', label: 'Claude 3 Sonnet' },
    { value: 'claude-3-haiku-20240307', label: 'Claude 3 Haiku' }
  ],
  groq: [
    { value: 'mixtral-8x7b-32768', label: 'Mixtral 8x7b' },
    { value: 'distil-whisper-large-v3-en', label: 'Distil Whisper' },
    { value: 'gemma2-9b-it', label: 'Gemma2 9B' },
    { value: 'gemma-7b-it', label: 'Gemma 7B' }
  ],
  gemini: [
    { value: 'gemini-1.5-pro', label: 'Gemini 1.5 Pro' },
    { value: 'gemini-1.5-flash', label: 'Gemini 1.5 Flash' }
  ],
  mistral: [
    { value: 'mistral-large-latest', label: 'Mistral Large' },
    { value: 'mistral-small-latest', label: 'Mistral Small' },
    { value: 'ministral-8b-latest', label: 'Ministral 8B' },
    { value: 'ministral-3b-latest', label: 'Ministral 3B' },
    { value: 'codestral-latest', label: 'Codestral' }
  ],
  // Default fallback options for OpenRouter if API call fails
  openrouterFallback: [
    { value: 'anthropic/claude-3-opus-20240229', label: 'Claude 3 Opus' },
    { value: 'anthropic/claude-3-sonnet-20240229', label: 'Claude 3 Sonnet' },
    { value: 'google/gemini-pro', label: 'Gemini Pro' },
    { value: 'meta-llama/llama-2-70b-chat', label: 'Llama 2 70B' },
    { value: 'mistral-ai/mistral-large', label: 'Mistral Large' },
    { value: 'mistral-ai/mistral-medium', label: 'Mistral Medium' }
  ]
}

// Helper function to get model options based on selected LLM
export const getModelOptions = async (selectedLLM) => {
  if (selectedLLM === 'openai') {
    try {
      const { models, error } = await getOpenAIModels();
      if (error) {
        console.error('Error fetching OpenAI models:', error);
        return MODEL_OPTIONS.openai; // Fallback to static list
      }
      return models;
    } catch (error) {
      console.error('Failed to fetch OpenAI models:', error);
      return MODEL_OPTIONS.openai; // Fallback to static list
    }
  }
  return MODEL_OPTIONS[selectedLLM] || MODEL_OPTIONS.openrouterFallback;
}
