import React, { createContext, useContext } from 'react';
import { useElevenLabsConversation } from '../hooks/useElevenLabsConversation';

const ElevenLabsContext = createContext(null);

export const ElevenLabsProvider = ({ children }) => {
  const conversation = useElevenLabsConversation({
    onMessageReceived: (message) => {
      console.log('Message received:', message);
    }
  });

  return (
    <ElevenLabsContext.Provider value={conversation}>
      {children}
    </ElevenLabsContext.Provider>
  );
};

export const useElevenLabs = () => {
  const context = useContext(ElevenLabsContext);
  if (!context) {
    throw new Error('useElevenLabs must be used within an ElevenLabsProvider');
  }
  return context;
}; 