import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Modal, Tooltip, message } from 'antd';
import { CloseOutlined, PauseOutlined, PlayCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import { LuFileAudio } from 'react-icons/lu';
import { supabase } from '../../supabaseClient';
import DefaultButton from '../ui/Buttons/defaultButton';
import PrimaryButton from '../ui/Buttons/primaryButton';
import './index.css';
import { useNavigate } from 'react-router-dom';

const circleVariants = {
  recording: {
    scale: [1, 1.2, 1],
    opacity: [1, 0.5, 1],
    transition: {
      duration: 1,
      repeat: Infinity,
      ease: 'easeInOut'
    }
  },
  paused: {
    scale: 1,
    opacity: 1
  }
};

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const AudioRecorder = ({ 
  onRecordingComplete = () => {}, 
  onRecordingStart = () => {}, 
  onRecordingStop = () => {},
  username = 'User',
  secondary = false
}) => {
  const [open, setOpen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [transcript, setTranscript] = useState('');
  const [interimTranscript, setInterimTranscript] = useState('');
  
  const recognition = useRef(null);
  const timerRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const navigate = useNavigate();

  // Add refs for tooltip targets
  const audioButtonRef = useRef(null);
  const audioIconRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
    startRecording();
  };

  const handleClose = () => {
    setOpen(false);
    stopRecording();
  };

  const startTimer = useCallback(() => {
    if (timerRef.current === null) {
      timerRef.current = setInterval(() => {
        setRecordingTime(prev => prev + 1);
      }, 1000);
    }
  }, []);

  const stopTimer = useCallback(() => {
    if (timerRef.current !== null) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  useEffect(() => {
    console.log('Initializing Web Speech Recognition');
    if ('webkitSpeechRecognition' in window) {
      recognition.current = new window.webkitSpeechRecognition();
      recognition.current.continuous = true;
      recognition.current.interimResults = true;
      recognition.current.lang = 'en-US';

      recognition.current.onstart = () => {
        console.log('Speech recognition started');
        message.info('Listening...');
      };

      recognition.current.onend = () => {
        console.log('Speech recognition ended');
      };

      recognition.current.onresult = (event) => {
        console.log('Speech recognition result received:', event.results);
        let finalTranscript = '';
        let currentInterim = '';

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          const transcriptPart = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            console.log('Final transcript part:', transcriptPart);
            finalTranscript += transcriptPart;
          } else {
            currentInterim += transcriptPart;
          }
        }

        setInterimTranscript(currentInterim);

        if (finalTranscript) {
          console.log('Adding to transcript:', finalTranscript);
          setTranscript(prevTranscript => {
            const newTranscript = prevTranscript ? `${prevTranscript} ${finalTranscript}` : finalTranscript;
            console.log('New complete transcript:', newTranscript);
            return newTranscript;
          });
        }
      };

      recognition.current.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        message.error(`Error: ${event.error}`);
      };
    } else {
      message.error('Speech recognition not supported in this browser');
    }

    return () => {
      if (recognition.current) {
        recognition.current.stop();
      }
    };
  }, []);

  const startAudioRecording = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.start();
    } catch (error) {
      console.error('Error accessing microphone:', error);
      message.error('Failed to access microphone');
    }
  }, []);

  const startRecording = useCallback(async () => {
    if (!recognition.current) {
      message.error('Speech recognition not supported');
      return;
    }

    try {
      await startAudioRecording();
      recognition.current.start();
      setIsRecording(true);
      setTranscript('');
      setRecordingTime(0);
      startTimer();
      console.log('Recording started');
    } catch (error) {
      console.error('Error starting recording:', error);
      message.error(`Failed to start recording: ${error.message}`);
    }
  }, [startTimer, startAudioRecording]);

  const pauseRecording = useCallback(() => {
    if (recognition.current && isRecording) {
      recognition.current.stop();
      setIsPaused(true);
      stopTimer();
    }
  }, [isRecording, stopTimer]);

  const resumeRecording = useCallback(() => {
    if (recognition.current && isRecording && isPaused) {
      recognition.current.start();
      setIsPaused(false);
      startTimer();
    }
  }, [isRecording, isPaused, startTimer]);

  const generateFileName = () => {
    const now = new Date();
    return [
      'SR',
      'audio',
      String(now.getMonth() + 1).padStart(2, '0'),
      String(now.getDate()).padStart(2, '0'),
      String(now.getFullYear()),
      '', // Add empty string to create double underscore
      String(now.getHours()).padStart(2, '0'),
      String(now.getMinutes()).padStart(2, '0'),
      String(now.getSeconds()).padStart(2, '0')
    ].join('_') + '.webm';
  };

  const saveRecording = async (audioBlob, audioDuration) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error('No authenticated user found');
      }

      const fileName = generateFileName();
      const filePath = `${user.id}/${fileName}`;

      // Upload audio file
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from('recordings')
        .upload(filePath, audioBlob, {
          cacheControl: '3600',
          upsert: false,
          contentType: 'audio/webm;codecs=opus'
        });

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: urlData } = supabase.storage
        .from('recordings')
        .getPublicUrl(filePath);

      // Create recording entry
      const { data: recordingData, error: recordingError } = await supabase
        .from('recordings')
        .insert({
          user_id: user.id,
          name: fileName,
          url: urlData.publicUrl,
          type: 'audio',
          duration: audioDuration,
          metadata: {
            mimeType: 'audio/webm;codecs=opus',
            duration: audioDuration,
            recordingTime: recordingTime
          }
        })
        .select()
        .single();

      if (recordingError) throw recordingError;

      // Save transcription
      const trimmedTranscript = transcript.trim();
      if (trimmedTranscript) {
        const transcriptionData = {
          content: trimmedTranscript,
          recording_id: recordingData.id,
          user_id: user.id,
          created_at: new Date().toISOString()
        };

        const { error: transcriptionError } = await supabase
          .from('transcriptions')
          .insert(transcriptionData);

        if (transcriptionError) throw transcriptionError;
      }

      message.success('Recording saved successfully');
      setOpen(false);
      return recordingData;
    } catch (error) {
      console.error('Error saving recording:', error);
      message.error(`Error: ${error.message}`);
      throw error;
    }
  };

  const stopRecording = useCallback(async () => {
    console.log('Stopping recording...');
    if (recognition.current && isRecording) {
      recognition.current.stop();
      setIsRecording(false);
      setIsPaused(false);
      stopTimer();

      if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
        return new Promise((resolve) => {
          mediaRecorderRef.current.onstop = async () => {
            try {
              const trimmedTranscript = transcript.trim();
              if (!trimmedTranscript) {
                console.warn('No transcript to save - empty content');
                message.warning('No speech detected');
                return;
              }

              const audioBlob = new Blob(audioChunksRef.current, { 
                type: 'audio/webm;codecs=opus' 
              });

              // Create audio element to get duration
              const audioURL = URL.createObjectURL(audioBlob);
              const audio = new Audio();
              
              await new Promise((resolveAudio) => {
                audio.addEventListener('loadedmetadata', async () => {
                  const audioDuration = audio.duration || recordingTime;
                  console.log('Calculated audio duration:', {
                    fromAudio: audio.duration,
                    fromTimer: recordingTime,
                    final: audioDuration
                  });
                  
                  try {
                    await saveRecording(audioBlob, audioDuration);
                  } catch (error) {
                    console.error('Error in saveRecording:', error);
                  } finally {
                    URL.revokeObjectURL(audioURL);
                    resolveAudio();
                  }
                });

                audio.addEventListener('error', (e) => {
                  console.error('Error loading audio:', e);
                  saveRecording(audioBlob, recordingTime).finally(() => {
                    URL.revokeObjectURL(audioURL);
                    resolveAudio();
                  });
                });

                audio.src = audioURL;
              });

            } catch (error) {
              console.error('Error saving recording:', error);
              message.error(`Error: ${error.message}`);
            }
            resolve();
          };

          mediaRecorderRef.current.stop();
          mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
        });
      }
    }
  }, [isRecording, stopTimer, transcript, recordingTime]);

  const cancelRecording = useCallback(() => {
    if (recognition.current && isRecording) {
      recognition.current.stop();
      setIsRecording(false);
      setIsPaused(false);
      setRecordingTime(0);
      setOpen(false);
    }
  }, [isRecording, stopTimer]);

  useEffect(() => {
    return () => {
      if (timerRef.current !== null) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  const getUserInitial = () => {
    if (!username || typeof username !== 'string') {
      return 'U'; // Default initial if username is not provided or invalid
    }
    return username.charAt(0).toUpperCase();
  };

  return (
    <>
      {secondary ? (
        <PrimaryButton 
          ref={audioButtonRef}
          shape='circle' 
          onClick={handleOpen}
        >
          <Tooltip 
            title='Transcribe'
            getPopupContainer={() => audioButtonRef.current}
          >
            <span ref={audioIconRef} className="icon-wrapper">
              <LuFileAudio style={{ color: '#ec2f6e' }} />
            </span>
          </Tooltip>
        </PrimaryButton>
      ) : (
        <DefaultButton 
          ref={audioButtonRef}
          shape='circle' 
          onClick={handleOpen}
        >
          <Tooltip 
            title='Transcribe'
            getPopupContainer={() => audioButtonRef.current}
          >
            <span ref={audioIconRef} className="icon-wrapper">
              <LuFileAudio />
            </span>
          </Tooltip>
        </DefaultButton>
      )}

      <Modal
        className='custom-modal'
        title={<span style={{ color: 'var(--text-color)' }}>Recording...</span>}
        open={open}
        onCancel={handleClose}
        footer={null}
        centered
        closeIcon={<CloseOutlined style={{ color: 'var(--text-color)' }} />}
        styles={{
          content: { backgroundColor: 'var(--bg-color)' },
          header: { backgroundColor: 'var(--bg-color)' },
          body: { backgroundColor: 'var(--bg-color)' }
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: 20,
            backgroundColor: 'var(--bg-color)'
          }}
        >
          <motion.div
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            animate={isRecording && !isPaused ? 'recording' : 'paused'}
            variants={circleVariants}
          >
            <div
              style={{
                width: 80,
                height: 80,
                borderRadius: '50%',
                backgroundColor: '#5684e6',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '2rem',
                color: 'white',
                zIndex: 2
              }}
            >
              {getUserInitial()}
            </div>
          </motion.div>

          <div style={{ fontSize: '1.2rem', color: 'var(--sec-text-color)', marginTop: 10 }}>
            {formatTime(recordingTime)}
          </div>
        </div>

        <div className="transcript-container">
          {transcript && (
            <div className="final-transcript">{transcript}</div>
          )}
          {interimTranscript && (
            <div className="interim-transcript">{interimTranscript}</div>
          )}
          {!transcript && !interimTranscript && (
            <div className="empty-state">Start speaking to see transcription...</div>
          )}
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', width: '60%', margin: 'auto' }}>
          {isPaused ? (
            <Button
              style={{
                margin: 0,
                backgroundColor: 'var(--primary-color)',
                color: 'white',
                border: 'none'
              }}
              onClick={resumeRecording}
              icon={<PlayCircleOutlined />}
            >
              Resume
            </Button>
          ) : (
            <Button
              style={{
                margin: 0,
                backgroundColor: 'var(--primary-color)',
                color: 'var(--text-color)',
                border: 'none'
              }}
              onClick={pauseRecording}
              icon={<PauseOutlined />}
            >
              Pause
            </Button>
          )}
          <Button
            style={{
              margin: 0,
              backgroundColor: 'green',
              color: 'white',
              border: 'none'
            }}
            onClick={stopRecording}
            icon={<CheckOutlined />}
          >
            Finish
          </Button>
        </div>
      </Modal>
    </>
  );
};

// Add CSS for the icon wrapper
const styles = `
  .icon-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

// Add the styles to the document
if (!document.getElementById('audio-recorder-styles')) {
  const styleSheet = document.createElement("style");
  styleSheet.id = 'audio-recorder-styles';
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);
}

export default AudioRecorder;
