import React from 'react';
import { Button } from 'antd';

const PrimaryButton = React.forwardRef((props, ref) => {
  return (
    <Button ref={ref} type="primary" {...props} />
  );
});

export default PrimaryButton;
