import React, { useState, useEffect, useRef, useMemo } from 'react'
import {
  Input,
  Button,
  Select,
  Tooltip,
  message
} from 'antd'
import { SendOutlined, MessageOutlined, MenuOutlined, HistoryOutlined } from '@ant-design/icons'
import { AuthProvider, useAuth } from '../../AuthContext'
import { getModelOptions, MODEL_OPTIONS } from '../../constants/models'
import { getOpenRouterModels } from '../../langchain'
import debounce from 'lodash/debounce'
import TTSService from '../../services/TTSService'
import AudioRecorder from '../AudioRecorder'
import PrimaryButton from '../ui/Buttons/primaryButton'
import DefaultButton from '../ui/Buttons/defaultButton'
import { LuFileAudio } from 'react-icons/lu'
import { FaArrowUp } from 'react-icons/fa'
import { FaMicrophone } from 'react-icons/fa'
import { RiRobot2Line } from 'react-icons/ri'
import {
  FiEdit2,
  FiRefreshCw,
  FiVolume2,
  FiThumbsUp,
  FiThumbsDown,
  FiShare2,
  FiPause
} from 'react-icons/fi'
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined'
import logo from '../../assets/images/logo.png'
import ElevenLabsWidget from '../ElevenLabsWidget'
import voiceIcon from '../../assets/images/voice.png'

// Import CSS files in correct order
import './index.css'
import './styles/layout.css'
import './styles/messages.css'
import './styles/input.css'
import './styles/header.css'
import './styles/intro.css'

const MainChat = ({
  messages,
  input,
  setInput,
  sendMessage,
  isLoading,
  selectedLLM,
  defaultModel,
  onModelChange,
  openRouterModels = [],
  onOpenSidebar,
  playTTSAudio,
  isPlaying,
  drawerOpen = true,
  chatTitle,
}) => {
  const { user, loading } = useAuth()
  const [playingMessageIndex, setPlayingMessageIndex] = useState(null)
  const [localIsPlaying, setLocalIsPlaying] = useState(false)
  const [currentText, setCurrentText] = useState(null)
  const chatDisplayRef = useRef(null);
  const [modelOptions, setModelOptions] = useState([])
  const [isLoadingModels, setIsLoadingModels] = useState(false)
  const [isVoiceWidgetOpen, setIsVoiceWidgetOpen] = useState(false)

  // Add refs for tooltip targets
  const audioButtonRef = useRef(null);
  const micButtonRef = useRef(null);
  const voiceButtonRef = useRef(null);
  const sendButtonRef = useRef(null);
  const audioIconRef = useRef(null);
  const micIconRef = useRef(null);
  const voiceIconRef = useRef(null);
  const sendIconRef = useRef(null);

  const examples = [
    "What can you do?",
    "Tell me a joke",
    "What's the weather like?"
  ]

  const capabilities = [
    "Summarize articles",
    "Set reminders",
    "Perform calculations"
  ]
  
  const limitations = [
    "May provide outdated info",
    "May provide outdated info",
    "Doesn't have real-time updates",
    "Limited memory"
  ]

  useEffect(() => {
    const loadModelOptions = async () => {
      setIsLoadingModels(true)
      try {
        if (selectedLLM === 'openrouter') {
          const { models, isRateLimited } = await getOpenRouterModels()
          if (isRateLimited) {
            message.warning('OpenRouter API rate limit exceeded. Using fallback models.')
          }
          setModelOptions(models.length > 0 ? models : getModelOptions('openrouter'))
        } else {
          setModelOptions(getModelOptions(selectedLLM))
        }
      } catch (error) {
        console.error('Error loading models:', error)
        setModelOptions(getModelOptions(selectedLLM))
      } finally {
        setIsLoadingModels(false)
      }
    }
    loadModelOptions()
  }, [selectedLLM])

  const handleSendMessage = () => {
    sendMessage()
  }

  const handleInputKeyPress = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSendMessage()
    }
  }

  const handlePlayback = async (messageText, index) => {
    const isCurrentlyPlaying = playingMessageIndex === index && localIsPlaying;
    const isSameText = currentText === messageText;
    
    try {
      if (isCurrentlyPlaying) {
        setLocalIsPlaying(false);
        await playTTSAudio(messageText, true);
      } else if (playingMessageIndex === index && !localIsPlaying && isSameText) {
        setLocalIsPlaying(true);
        TTSService.resumePlayback();
      } else {
        if (playingMessageIndex !== null) {
          TTSService.stopPlayback();
        }
        setPlayingMessageIndex(index);
        setLocalIsPlaying(true);
        setCurrentText(messageText);
        await playTTSAudio(messageText, false);
      }
    } catch (error) {
      console.error('Playback error:', error);
      message.error('Failed to play audio. Please check your ElevenLabs API key in settings.');
      setLocalIsPlaying(false);
      setPlayingMessageIndex(null);
      setCurrentText(null);
    }
  };

  // Reset local state when playback finishes
  useEffect(() => {
    if (!isPlaying && localIsPlaying) {
      setLocalIsPlaying(false);
      setPlayingMessageIndex(null);
      setCurrentText(null);
    }
  }, [isPlaying]);

  // Add useEffect to scroll to bottom whenever messages change
  useEffect(() => {
    if (chatDisplayRef.current) {
      chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
    }
  }, [messages]);

  // Use direct input handling instead
  const handleInputChange = (e) => {
    setInput(e.target.value)
  }

  const handleVoiceMessage = (text) => {
    if (text && text.trim()) {
      setInput(text);
      // Optionally auto-send the message
      handleSendMessage(text);
    }
  };

  return (
    <>
      <div className={`chat-screen-container ${!drawerOpen ? 'drawer-closed' : ''} parent-container`}>
        {/* Desktop history button */}
        <div className="history-button-container desktop-only">
          <DefaultButton
            ref={voiceButtonRef}
            onClick={onOpenSidebar}
            icon={<HistoryOutlined />}
          />
        </div>

        {/* Mobile history button */}
        <div className="history-button-container mobile-only">
          <DefaultButton
            onClick={onOpenSidebar}
            icon={<HistoryOutlined />}
          />
        </div>

          <div className="mobile-chat-title">
            <span className="title-text">{messages.length > 0 ? chatTitle : "New Chat"}</span>
          </div>

          <div className="chat-header">
          </div>

          <div className="chat-messages-wrapper" ref={chatDisplayRef}>
              {messages.length === 0 ? (
                <div className="ai-intro-container">
                  <h1 className="welcome-text">
                    <span className="ai-text"> Welcome to </span>
                    <span className="solo-recall-gradient">SoLoRecall</span>
                    <span className="ai-text"> AI</span>
                  </h1>
                  <p className="welcome-subtitle">Your personal AI assistant for learning and discovery</p>
                    <div className="input-row">
                      <div className="input-with-buttons">
                        <Input.TextArea
                          placeholder="Type your message..."
                          value={input}
                          onChange={handleInputChange}
                          className="chat-input"
                          disabled={isLoading}
                          autoSize={{ minRows: 1, maxRows: 6 }}
                          onPressEnter={handleInputKeyPress}
                        />
                        <div className="input-actions">
                          <div className="voice-button-wrapper">
                            <DefaultButton 
                              ref={voiceButtonRef}
                              shape="circle"
                              onClick={() => setIsVoiceWidgetOpen(true)}
                              className="voice-button"
                            >
                              <Tooltip title="Voice Chat" getPopupContainer={() => voiceButtonRef.current}>
                                <span ref={voiceIconRef} className="icon-wrapper">
                                  <img 
                                    src={voiceIcon} 
                                    alt="Voice Chat" 
                                    width="24" 
                                    height="24" 
                                  />
                                </span>
                              </Tooltip>
                            </DefaultButton>
                          </div>
                          <DefaultButton 
                            ref={micButtonRef}
                            shape="circle"
                          >
                            <Tooltip title="Record audio" getPopupContainer={() => micButtonRef.current}>
                              <span ref={micIconRef} className="icon-wrapper">
                                <FaMicrophone />
                              </span>
                            </Tooltip>
                          </DefaultButton>
                          <DefaultButton 
                            ref={audioButtonRef}
                            shape="circle"
                          >
                            <Tooltip title="Audio file" getPopupContainer={() => audioButtonRef.current}>
                              <span ref={audioIconRef} className="icon-wrapper">
                                <LuFileAudio />
                              </span>
                            </Tooltip>
                          </DefaultButton>
                          <PrimaryButton
                            ref={sendButtonRef}
                            shape="circle"
                            icon={
                              <span ref={sendIconRef} className="icon-wrapper">
                                <FaArrowUp />
                              </span>
                            }
                            onClick={handleSendMessage}
                            disabled={isLoading || !input.trim()}
                          />
                        </div>
                      </div>
                      <div className="bottom-row">
                        <Select
                          value={defaultModel}
                          onChange={onModelChange}
                          options={modelOptions}
                          disabled={isLoading}
                          loading={isLoadingModels}
                          className="model-selector"
                          popupMatchSelectWidth={false}
                        />
                      </div>
                    </div>
                </div>
              ) : (
                messages.map((message, index) => (
                  <React.Fragment key={index}>
                    {message.sender !== 'user' ? (
                      <div className="message-bubble-ai">
                          <img 
                            src={logo} 
                            alt="logo" 
                            style={{ 
                              width: '24px', 
                              height: '24px', 
                              minWidth: '23px', 
                              minHeight: '23px',
                              objectFit: 'cover',
                              display: 'block'
                            }} 
                          />

                        <div>
                          <p className="chat-message-ai">{message.text}</p>

                          {/* Icon buttons under the message bubble */}
                          <div className="icon-buttons-container">
                            <button className="icon-button">
                              <Tooltip title="edit">
                                <FiEdit2 style={{ color: "#FF1493" }}/>
                              </Tooltip>
                            </button>
                            <button className="icon-button">
                              <Tooltip title="regenerate">
                                <FiRefreshCw style={{ color: "#9400D3" }}/>
                              </Tooltip>
                            </button>
                            <button 
                              className="icon-button"
                              onClick={() => handlePlayback(message.text, index)}
                            >
                              <Tooltip title={playingMessageIndex === index && localIsPlaying ? 'pause' : 'read aloud'}>
                                {playingMessageIndex === index && localIsPlaying ? (
                                  <FiPause style={{ color: "#e53935" }}/>
                                ) : (
                                  <FiVolume2 style={{ color: "#e53935" }}/>
                                )}
                              </Tooltip>
                            </button>
                            <button className="icon-button">
                              <Tooltip title="Like">
                                <FiThumbsUp style={{ color: "#00CED1" }}/>
                              </Tooltip>
                            </button>
                            <button className="icon-button">
                              <Tooltip title="dislike">
                                <FiThumbsDown style={{ color: "#FF69B4" }}/>
                              </Tooltip>
                            </button>
                            <button className="icon-button">
                              <Tooltip title="share">
                                <FiShare2 style={{ color: "#ff5722" }}/>
                              </Tooltip>
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="message-bubble-user">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '35px',
                            width: '35px',
                            minWidth: '35px',
                            minHeight: '35px',
                            borderRadius: '50%',
                            backgroundColor: '#e8056f',
                            fontSize: '18px',
                            color: 'white',
                            flexShrink: 0,
                          }}
                        >
                          {user.email.charAt(0).toUpperCase()}
                        </div>
                        <p className="chat-message-user">{message.text}</p>
                      </div>
                    )}
                  </React.Fragment>
                ))
              )} 
            
            
              <div className="chat-input-wrapper">
                <div className="input-row">
                  <div className="input-with-buttons">
                    <Input.TextArea
                      placeholder="Type your message..."
                      value={input}
                      onChange={handleInputChange}
                      className="chat-input"
                      disabled={isLoading}
                      autoSize={{ minRows: 1, maxRows: 6 }}
                      onPressEnter={handleInputKeyPress}
                    />
                    <div className="input-actions">
                      <div className="voice-button-wrapper">
                        <DefaultButton 
                          ref={voiceButtonRef}
                          shape="circle"
                          onClick={() => setIsVoiceWidgetOpen(true)}
                          className="voice-button"
                        >
                          <Tooltip title="Voice Chat" getPopupContainer={() => voiceButtonRef.current}>
                            <span ref={voiceIconRef} className="icon-wrapper">
                              <img 
                                src={voiceIcon} 
                                alt="Voice Chat" 
                                width="24" 
                                height="24" 
                              />
                            </span>
                          </Tooltip>
                        </DefaultButton>
                      </div>
                      <DefaultButton 
                        ref={micButtonRef}
                        shape="circle"
                      >
                        <Tooltip title="Record audio" getPopupContainer={() => micButtonRef.current}>
                          <span ref={micIconRef} className="icon-wrapper">
                            <FaMicrophone />
                          </span>
                        </Tooltip>
                      </DefaultButton>
                      <DefaultButton 
                        ref={audioButtonRef}
                        shape="circle"
                      >
                        <Tooltip title="Audio file" getPopupContainer={() => audioButtonRef.current}>
                          <span ref={audioIconRef} className="icon-wrapper">
                            <LuFileAudio />
                          </span>
                        </Tooltip>
                      </DefaultButton>
                      <PrimaryButton
                        ref={sendButtonRef}
                        shape="circle"
                        icon={
                          <span ref={sendIconRef} className="icon-wrapper">
                            <FaArrowUp />
                          </span>
                        }
                        onClick={handleSendMessage}
                        disabled={isLoading || !input.trim()}
                      />
                    </div>
                  </div>
                  <div className="bottom-row">
                    <Select
                      value={defaultModel}
                      onChange={onModelChange}
                      options={modelOptions}
                      disabled={isLoading}
                      loading={isLoadingModels}
                      className="model-selector"
                      popupMatchSelectWidth={false}
                    />
                  </div>
                </div>
              </div>
          </div>
      </div>
      {isVoiceWidgetOpen && (
        <ElevenLabsWidget 
          isOpen={isVoiceWidgetOpen} 
          onClose={() => setIsVoiceWidgetOpen(false)}
          onMessageReceived={handleVoiceMessage}
        />
      )}
    </>
  )
}

// Add CSS for the icon wrapper
const styles = `
  .icon-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

// Add the styles to the document
if (!document.getElementById('main-chat-styles')) {
  const styleSheet = document.createElement("style");
  styleSheet.id = 'main-chat-styles';
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);
}

export default React.memo(MainChat)
