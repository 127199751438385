import { supabase } from '../supabaseClient';

export const getCurrentSubscription = async (userId) => {
  try {
    const { data, error } = await supabase
      .from('subscriptions')
      .select('*')
      .eq('user_id', userId)
      .eq('is_active', true)
      .limit(1);

    if (error) {
      console.error('Error fetching current subscription:', error.message);
      return null;
    }

    console.log('subscription', data);
    return data;
  } catch (err) {
    console.error('Unexpected error fetching subscription:', err.message);
    return null;
  }
}; 