import React, { useEffect, useState } from 'react'
import './index.css'
import { SubscriptionCard } from './Card'
import { getSubscription } from '../../DatabaseService'
import { useAuth } from '../../AuthContext'
import { Spin } from 'antd'
import { stripe_price_ids_and_payment_links } from '../../constants/stripe'
import { useNavigate } from 'react-router-dom'

const SubscriptionPlans = () => {
  const { user } = useAuth()
  const [currentSubscription, setCurrentSubscription] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  // Main Subscription Plans Component
  const plans = [
    {
      title: 'Weekly Plan',
      price: '$6.99',
      duration: 'Billed Weekly',
      description: 'Explore how AI assists you with everyday tasks.',
      benefits: [
        'AI task assistance',
        'Access to basic features',
        'Email support',
        'Daily usage analytics',
        'Customizable settings',
        'Basic reporting features',
        'Integration with popular tools',
        'Community access'
      ],
      price_id: stripe_price_ids_and_payment_links['week']['price_id'],
      hosted_payment_link:
        stripe_price_ids_and_payment_links['week']['hosted_payment_link'],
      planName: 'week'
    },
    {
      title: 'Monthly Plan',
      price: '$19.99',
      duration: 'Billed Monthly',
      description: 'Unlock advanced AI features to supercharge your workflow.',
      benefits: [
        'AI task assistance',
        'Advanced productivity tools',
        'Priority email support',
        'Weekly usage analytics',
        'Customizable settings',
        'Advanced reporting features',
        'Integration with more tools',
        'Community access'
      ],
      price_id: stripe_price_ids_and_payment_links['month']['price_id'],
      hosted_payment_link:
        stripe_price_ids_and_payment_links['month']['hosted_payment_link'],
      planName: 'month'
    },
    {
      title: 'Yearly Plan',
      price: '$199.99',
      duration: 'Billed Annually',
      description:
        'Maximize efficiency with full AI capabilities and premium support.',
      benefits: [
        'All AI features unlocked',
        'Exclusive premium tools',
        '24/7 VIP support',
        'Monthly usage analytics',
        'Customizable settings',
        'Comprehensive reporting features',
        'Integration with all tools',
        'Access to beta features'
      ],
      price_id: stripe_price_ids_and_payment_links['year']['price_id'],
      hosted_payment_link:
        stripe_price_ids_and_payment_links['year']['hosted_payment_link'],
      planName: 'year'
    }
  ]

  const getCurrentUserSubscription = async user => {
    setLoading(true)
    try {
      const result = await getSubscription(user.id)
      setCurrentSubscription(result)
    } catch (error) {
      console.error('Error fetching subscription:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (user) {
      getCurrentUserSubscription(user)
    }
  }, [user])

  const refreshSubscription = () => {
    if (user) {
      getCurrentUserSubscription(user)
    }
  }

  if (loading) {
    return (
      <div className='loading-container'>
        <Spin tip='Loading Subscription Plans...' />
      </div>
    )
  }

  return (
    <div className='subscription-plans-container'>
      <h2 className='subscription-header'>Choose Your Plan</h2>
      <div className='plans-grid'>
        {currentSubscription
          ? // Render the current subscription only
            plans
              .filter(plan => plan.planName === currentSubscription.plan)
              .map((plan, index) => (
                <SubscriptionCard
                  key={index}
                  title={plan.title}
                  price={plan.price}
                  duration={plan.duration}
                  description={plan.description}
                  benefits={plan.benefits}
                  price_id={plan.price_id}
                  hosted_payment_link={plan.hosted_payment_link}
                  user={user}
                  isSubscribed={true}
                  onSubscriptionUpdate={refreshSubscription}
                />
              ))
          : // Render all plans if there's no active subscription
            plans.map((plan, index) => (
              <SubscriptionCard
                key={index}
                title={plan.title}
                price={plan.price}
                duration={plan.duration}
                description={plan.description}
                benefits={plan.benefits}
                price_id={plan.price_id}
                hosted_payment_link={plan.hosted_payment_link}
                user={user}
                isSubscribed={false}
                onSubscriptionUpdate={refreshSubscription}
              />
            ))}
      </div>
    </div>
  )
}

export default SubscriptionPlans
