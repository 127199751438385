// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.welcome-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  margin-top: 10vh;
}

.welcome-subtitle {
  text-align: center;
  margin-top: 1rem;
  width: 100%;
  font-size: 1.2rem;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .chat-screen-container {
    padding: 0;
    width: 100%;
    margin-right: 0;
    transition: none;
  }

  .chat-screen-container:not(.drawer-closed) {
    width: 100%;
    margin-right: 0;
  }

 

  .welcome-text {
    font-size: calc(1.5rem + 2vw);
    margin-top: 5vh;
  }

  .welcome-subtitle {
    font-size: calc(0.9rem + 1vw);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/MainChatComponent/index.css"],"names":[],"mappings":";;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,iBAAiB;AACnB;;AAEA,uBAAuB;AACvB;EACE;IACE,UAAU;IACV,WAAW;IACX,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;;;;EAIA;IACE,6BAA6B;IAC7B,eAAe;EACjB;;EAEA;IACE,6BAA6B;EAC/B;AACF","sourcesContent":["\n\n.welcome-text {\n  word-wrap: break-word;\n  overflow-wrap: break-word;\n  white-space: normal;\n  width: 100%;\n  text-align: center;\n  font-size: 2.5rem;\n  margin-top: 10vh;\n}\n\n.welcome-subtitle {\n  text-align: center;\n  margin-top: 1rem;\n  width: 100%;\n  font-size: 1.2rem;\n}\n\n/* Mobile adjustments */\n@media (max-width: 768px) {\n  .chat-screen-container {\n    padding: 0;\n    width: 100%;\n    margin-right: 0;\n    transition: none;\n  }\n\n  .chat-screen-container:not(.drawer-closed) {\n    width: 100%;\n    margin-right: 0;\n  }\n\n \n\n  .welcome-text {\n    font-size: calc(1.5rem + 2vw);\n    margin-top: 5vh;\n  }\n\n  .welcome-subtitle {\n    font-size: calc(0.9rem + 1vw);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
