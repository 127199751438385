// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-button {
  color: var(--text-color);
  background-color: #ececec;
  border: none;
  margin: 0 !important;
}

.primary-button:hover,
.primary-button:focus {
  background-color: var(--primary-color-hover) !important;
  color: var(--text-color) !important;
  border: none !important;
}

.secondary-button {
  color: var(--text-color);
  background-color: transparent;
  border: 1px solid var(--text-color);
  margin: 0 !important;
}

.secondary-button:hover,
.secondary-button:focus {
  color: var(--text-color) !important;
  border-color: var(--text-color) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.default-button {
  color: var(--text-color);
  background-color: transparent;
  box-shadow: none !important;
  border: none;
  margin: 0 !important;
}

.default-button:hover,
.default-button:focus {
  background-color: rgba(0, 0, 0, 0.04) !important;
  color: var(--text-color) !important;
}

`, "",{"version":3,"sources":["webpack://./src/components/ui/Buttons/index.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,yBAAyB;EACzB,YAAY;EACZ,oBAAoB;AACtB;;AAEA;;EAEE,uDAAuD;EACvD,mCAAmC;EACnC,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;EACxB,6BAA6B;EAC7B,mCAAmC;EACnC,oBAAoB;AACtB;;AAEA;;EAEE,mCAAmC;EACnC,0CAA0C;EAC1C,gDAAgD;AAClD;;AAEA;EACE,wBAAwB;EACxB,6BAA6B;EAC7B,2BAA2B;EAC3B,YAAY;EACZ,oBAAoB;AACtB;;AAEA;;EAEE,gDAAgD;EAChD,mCAAmC;AACrC","sourcesContent":[".primary-button {\n  color: var(--text-color);\n  background-color: #ececec;\n  border: none;\n  margin: 0 !important;\n}\n\n.primary-button:hover,\n.primary-button:focus {\n  background-color: var(--primary-color-hover) !important;\n  color: var(--text-color) !important;\n  border: none !important;\n}\n\n.secondary-button {\n  color: var(--text-color);\n  background-color: transparent;\n  border: 1px solid var(--text-color);\n  margin: 0 !important;\n}\n\n.secondary-button:hover,\n.secondary-button:focus {\n  color: var(--text-color) !important;\n  border-color: var(--text-color) !important;\n  background-color: rgba(0, 0, 0, 0.04) !important;\n}\n\n.default-button {\n  color: var(--text-color);\n  background-color: transparent;\n  box-shadow: none !important;\n  border: none;\n  margin: 0 !important;\n}\n\n.default-button:hover,\n.default-button:focus {\n  background-color: rgba(0, 0, 0, 0.04) !important;\n  color: var(--text-color) !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
