import axios from 'axios';

class TTSService {
  constructor() {
    this.currentProvider = 'elevenlabs'; // Set ElevenLabs as default
    this.audioContext = null;
    this.audioSource = null;
    this.isPaused = false;
    
    this.providers = {
      elevenlabs: {
        voices: {},
        audioElement: new Audio()
      },
      google: {
        voices: {},
        audioElement: new Audio()
      }
    };
  }

  // Update the initialize method to focus on ElevenLabs
  async initialize() {
    try {
      // Only initialize ElevenLabs
      await this.initializeElevenLabs();
    } catch (error) {
      console.error('Error initializing TTS service:', error);
    }
  }

  async initializeElevenLabs() {
    // ElevenLabs initialization logic here
    // We'll keep this as is since it's working
  }

  // Remove or comment out Google TTS related code
  // async initializeGoogleTTS() {
  //   // Comment out Google TTS initialization
  // }

  async convertText(text, voiceId, apiKey) {
    if (!text) return;
    
    try {
      // Always use ElevenLabs
      return await this.convertWithElevenLabs(text, voiceId, apiKey);
    } catch (error) {
      console.error('TTS conversion error:', error);
      throw error;
    }
  }

  // Keep other methods but remove Google TTS specific code
  // ...rest of the class implementation
}

export default new TTSService();
