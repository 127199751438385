import React, { useRef, useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useAudioVisualizer } from '../../hooks/useAudioVisualizer';
import { useElevenLabsConversation } from '../../hooks/useElevenLabsConversation';
import './styles.css';

const ElevenLabsWidget = ({ isOpen, onClose, onMessageReceived }) => {
  const canvasRef = useRef(null);
  const hasInitializedRef = useRef(false);
  const [error, setError] = useState(null);
  
  const {
    start,
    stop,
    isProcessing,
    isRecording: isListening,
    isAiSpeaking,
    error: conversationError
  } = useElevenLabsConversation({
    onMessageReceived
  });

  useEffect(() => {
    const initializeConversation = async () => {
      if (isOpen && !hasInitializedRef.current) {
        try {
          hasInitializedRef.current = true;
          await start();
        } catch (err) {
          const errorMessage = err.message.includes('API key') 
            ? 'Please add your ElevenLabs API key in settings to use voice chat.'
            : err.message || 'Failed to initialize conversation';
          setError(errorMessage);
          console.error('Initialization error:', err);
          hasInitializedRef.current = false;
        }
      }
    };

    initializeConversation();

    return () => {
      if (!isOpen) {
        stop();
        hasInitializedRef.current = false;
      }
    };
  }, [isOpen, start, stop]);

  // Update error state when conversation error occurs
  useEffect(() => {
    if (conversationError) {
      setError(conversationError);
    }
  }, [conversationError]);

  useAudioVisualizer({ 
    canvasRef, 
    isListening,
    isAiSpeaking
  });

  if (!isOpen) return null;

  return (
    <div className="eleven-labs-widget">
      <div className="voice-widget-overlay">
        <div className="voice-widget-container">
          <div className="voice-widget-header">
            <h3>
              {isAiSpeaking ? 'AI Speaking' : 
               isListening ? 'Listening...' : 
               isProcessing ? 'Processing...' : 'Ready'}
            </h3>
            <button 
              className="close-button" 
              onClick={() => {
                stop();
                onClose();
              }}
            >
              <IoClose size={24} />
            </button>
          </div>

          <div className={`voice-visualization ${
            isAiSpeaking ? 'ai-speaking' : 
            isListening ? 'user-speaking' : ''
          }`}>
            <canvas
              ref={canvasRef}
              className="visualization-canvas"
              width={600}
              height={200}
            />
          </div>

          {error && (
            <div className="error-message">
              {error}
            </div>
          )}

          <div className="voice-widget-status">
            {isProcessing ? 'Processing your voice...' : 
             isListening ? 'Listening to you...' :
             isAiSpeaking ? 'AI is speaking...' : 
             'Click Start to begin conversation'}
          </div>

          <div className="voice-widget-controls">
            <button 
              className={`control-button ${isListening ? 'end-button' : 'start-button'}`}
              onClick={isListening ? stop : start}
              disabled={isProcessing || isAiSpeaking}
            >
              {isListening ? 'Stop' : 'Start'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElevenLabsWidget; 