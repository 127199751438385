import React from 'react'
import { FiTrash2, FiMessageSquare } from 'react-icons/fi'

const ChatList = ({
  sessions = [],
  onSelectSession = () => {},
  onDeleteSession = () => {},
  currentSessionId,
  onNewSession = () => {}
}) => {
  return (
    <div className='chat-list drawer-open'>
      <div className="sticky-header">
        <button className="new-chat-btn" onClick={onNewSession}>
          New Chat
        </button>
      </div>
      <div className="scrollable-content">
        {sessions.length === 0 ? (
          <div className="empty-state">
            <FiMessageSquare size={24} />
            <p>No chat sessions yet</p>
          </div>
        ) : (
          <ul>
            {sessions.map(session => (
              <li
                key={session.session_id}
                className={session.session_id === currentSessionId ? 'active' : ''}
                onClick={() => onSelectSession(session.session_id)}
              >
                <span className="session-title">{session.title || 'Untitled Chat'}</span>
                <button
                  type="button"
                  className="delete-button"
                  onClick={(e) => {
                    e.stopPropagation()
                    onDeleteSession(session.session_id)
                  }}
                  aria-label="Delete chat session"
                >
                  <FiTrash2 />
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default ChatList
