import { supabase } from '../supabaseClient';

export const uploadAudioRecording = async (userId, blob, duration) => {
  // ... implementation
};

export const fetchResourceById = async (resourceId) => {
  // ... implementation
};

export const fetchRecordings = async (userId) => {
  // ... implementation
};

export const uploadTranscription = async (userId, transcriptContent, recordingId, fileName) => {
  // ... implementation
};

export const fetchTranscription = async (recordingId) => {
  try {
    const { data, error } = await supabase
      .from('transcriptions')
      .select('*')
      .eq('recording_id', recordingId)
      .single();

    if (error) {
      if (error.code === 'PGRST116') {
        console.log('No transcription found for this recording');
        return null;
      }
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error fetching transcription:', error);
    return null;
  }
}; 