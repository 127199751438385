import React, { useEffect, useState } from 'react'
import { Table, Tag } from 'antd'
// import 'antd/dist/antd.css'
import './index.css' // Custom CSS for styling
import { getSubscriptions } from '../../DatabaseService'
import { useAuth } from '../../AuthContext'

// Mock data for the table
const mockSubscriptions = [
  {
    plan: 'Basic',
    status: 'Active',
    startDate: '2023-01-01',
    endDate: '2024-01-01'
  },
  {
    plan: 'Premium',
    status: 'Inactive',
    startDate: '2022-01-01',
    endDate: '2023-01-01'
  }
]

// Define columns for the table
const columns = [
  {
    title: 'Plan',
    dataIndex: 'plan',
    key: 'plan'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: status => (
      <Tag color={status === 'active' ? 'green' : 'red'}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Tag>
    )
  },
  {
    title: 'Start Date',
    dataIndex: 'start_date',
    key: 'startDate',
    render: date =>
      new Date(date).toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      })
  },
  {
    title: 'End Date',
    dataIndex: 'end_date',
    key: 'endDate',
    render: date =>
      new Date(date).toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      })
  }
]

const MySubscriptionsPage = () => {
  const [subscriptions, setSubscriptions] = useState([])
  const { user } = useAuth()

  const getMySubscription = async userId => {
    const subs = await getSubscriptions(userId)
    setSubscriptions(subs)
  }

  useEffect(() => {
    if (user && user.id) {
      getMySubscription(user.id)
    }
  }, [user])

  return (
    <div className='subscriptions-container'>
      <h2 className='heading'>My Subscriptions</h2>
      <div className='subscriptions-content'>
        <Table
          columns={columns}
          dataSource={subscriptions}
          rowKey='plan'
          pagination={false}
          scroll={{ x: true }}
        />
      </div>
    </div>
  )
}

export default MySubscriptionsPage
