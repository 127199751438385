import { supabase } from '../supabaseClient';

class UserSettingsService {
  async getCurrentUser() {
    try {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) throw error;
      if (!user) throw new Error('No authenticated user');
      return user;
    } catch (error) {
      console.error('Auth error:', error);
      throw new Error('Authentication required');
    }
  }

  // API Keys Management
  async saveApiKey(provider, apiKey) {
    console.log('Saving API key for provider:', provider);
    
    try {
      const user = await this.getCurrentUser();

      const { data: existingKey, error: fetchError } = await supabase
        .from('user_api_keys')
        .select('id')
        .eq('provider', provider)
        .eq('user_id', user.id)
        .single();

      if (fetchError && fetchError.code !== 'PGRST116') {
        console.error('Error checking existing API key:', fetchError);
        throw fetchError;
      }

      if (existingKey) {
        const { error } = await supabase
          .from('user_api_keys')
          .update({ 
            api_key: apiKey, 
            updated_at: new Date().toISOString() 
          })
          .eq('id', existingKey.id)
          .eq('user_id', user.id);

        if (error) throw error;
      } else {
        const { error } = await supabase
          .from('user_api_keys')
          .insert({ 
            provider, 
            api_key: apiKey,
            user_id: user.id
          });

        if (error) throw error;
      }
    } catch (error) {
      console.error('Error in saveApiKey:', error);
      throw error;
    }
  }

  async getApiKey(provider) {
    try {
      const user = await this.getCurrentUser();

      const { data, error } = await supabase
        .from('user_api_keys')
        .select('api_key')
        .eq('provider', provider)
        .eq('user_id', user.id)
        .single();

      if (error) {
        if (error.code === 'PGRST116') return null;
        throw error;
      }

      return data?.api_key || null;
    } catch (error) {
      console.error('Error in getApiKey:', error);
      throw error;
    }
  }

  async getAllApiKeys() {
    try {
      const user = await this.getCurrentUser();

      const { data, error } = await supabase
        .from('user_api_keys')
        .select('provider, api_key')
        .eq('user_id', user.id);

      if (error) throw error;

      return data.reduce((acc, { provider, api_key }) => {
        acc[provider] = api_key;
        return acc;
      }, {});
    } catch (error) {
      console.error('Error in getAllApiKeys:', error);
      throw error;
    }
  }

  // Settings Management
  async saveSettings(settings) {
    try {
      const user = await this.getCurrentUser();
      
      // First try to get existing settings
      const { data: existingData, error: fetchError } = await supabase
        .from('user_settings')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (fetchError && fetchError.code !== 'PGRST116') {
        throw fetchError;
      }

      if (existingData) {
        // Update existing settings
        const { data, error } = await supabase
          .from('user_settings')
          .update({ settings })
          .eq('user_id', user.id)
          .select()
          .single();

        if (error) throw error;
        return data;
      } else {
        // Insert new settings
        const { data, error } = await supabase
          .from('user_settings')
          .insert({ 
            user_id: user.id,
            settings 
          })
          .select()
          .single();

        if (error) throw error;
        return data;
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      throw error;
    }
  }

  async getSettings() {
    try {
      const user = await this.getCurrentUser();

      const { data, error } = await supabase
        .from('user_settings')
        .select('settings')
        .eq('user_id', user.id)
        .maybeSingle(); // Use maybeSingle instead of single

      if (error) throw error;

      if (!data) {
        // Create default settings if none exist
        const defaultSettings = {
          selectedLLM: 'openai',
          defaultModel: 'gpt-3.5-turbo',
          readAloud: false,
          ttsProvider: 'elevenlabs',
          selectedVoice: '21m00Tcm4TlvDq8ikWAM',
          conversationSettings: {
            isEnabled: false,
            selectedVoice: null,
            stability: 0.5,
            similarity: 0.75,
            style: 'balanced'
          }
        };

        const { data: newData, error: saveError } = await supabase
          .from('user_settings')
          .insert({ 
            user_id: user.id,
            settings: defaultSettings 
          })
          .select()
          .single();

        if (saveError) throw saveError;
        return newData.settings;
      }

      return data.settings;
    } catch (error) {
      console.error('Error getting settings:', error);
      throw error;
    }
  }

  async updateSettings(partialSettings) {
    console.log('Updating user settings:', partialSettings);
    
    try {
      const currentSettings = await this.getSettings();
      const updatedSettings = {
        ...currentSettings,
        ...partialSettings
      };

      await this.saveSettings(updatedSettings);
      console.log('Successfully updated user settings');
      return updatedSettings;
    } catch (error) {
      console.error('Error in updateSettings:', error);
      throw error;
    }
  }

  // Add test methods to UserSettingsService
  async testDatabaseConnection() {
    try {
      const user = await this.getCurrentUser();

      console.log('Test: Starting database connection test...');

      // Test 1: Save settings
      const testSettings = {
        testKey: 'testValue',
        timestamp: new Date().toISOString()
      };
      console.log('Test 1: Saving test settings...', testSettings);
      await this.saveSettings(testSettings);
      console.log('Test 1: ✅ Settings saved successfully');

      // Test 2: Get settings
      console.log('Test 2: Retrieving settings...');
      const retrievedSettings = await this.getSettings();
      console.log('Test 2: Retrieved settings:', retrievedSettings);
      console.log('Test 2: ✅ Settings retrieved successfully');

      // Test 3: Save API key
      console.log('Test 3: Saving test API key...');
      await this.saveApiKey('test_provider', 'test_key_123');
      console.log('Test 3: ✅ API key saved successfully');

      // Test 4: Get API key
      console.log('Test 4: Retrieving API key...');
      const apiKey = await this.getApiKey('test_provider');
      console.log('Test 4: Retrieved API key:', apiKey);
      console.log('Test 4: ✅ API key retrieved successfully');

      // Test 5: Get all API keys
      console.log('Test 5: Retrieving all API keys...');
      const allKeys = await this.getAllApiKeys();
      console.log('Test 5: Retrieved all keys:', allKeys);
      console.log('Test 5: ✅ All API keys retrieved successfully');

      console.log('✅ All tests completed successfully!');
      return true;
    } catch (error) {
      console.error('❌ Test failed:', error);
      throw error;
    }
  }

  async getElevenLabsApiKey() {
    try {
      const apiKey = await this.getApiKey('elevenlabs');
      if (!apiKey) {
        throw new Error('ElevenLabs API key not found. Please add it in settings.');
      }
      return apiKey;
    } catch (error) {
      console.error('Error getting ElevenLabs API key:', error);
      throw error;
    }
  }
}

export default new UserSettingsService(); 