export const stripe_price_ids_and_payment_links = {
  week: {
    price_id: "price_1Q70yIF4sumRfAeClXD5yshR",
    hosted_payment_link: "https://buy.stripe.com/eVadS97cY1uQ1vG3ch",
  },
  month: {
    price_id: "price_1Q70yIF4sumRfAeCNjeYxZgq",
    hosted_payment_link: "https://buy.stripe.com/cN2g0hfJu1uQ7U47sw",
  },
  year: {
    price_id: "price_1Q70yIF4sumRfAeCUURP43Ok",
    hosted_payment_link: "https://buy.stripe.com/eVadS98h23CYfmw5kn",
  },
};
