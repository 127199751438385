import { useCallback } from 'react';
import { useSettings } from '../contexts/SettingsContext';
import TTSService from '../services/TTSService';

export const useTTS = () => {
  const { apiKeys, settings } = useSettings();
  
  const convertText = useCallback(async (text, voiceId) => {
    const apiKey = apiKeys.elevenlabs || 
                  process.env.REACT_APP_ELEVEN_LABS_API_KEY;
                  
    return TTSService.convertWithElevenLabs(text, voiceId, apiKey, {
      stability: settings?.conversationSettings?.stability,
      similarity: settings?.conversationSettings?.similarity
    });
  }, [apiKeys, settings]);

  return {
    convertText,
    ...TTSService
  };
}; 