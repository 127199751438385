// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscriptions-container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: center;
  /* align-items: center; */
  /* min-height: 100vh; */
  width: 100%;
  background-color: #fff;
}

.subscriptions-content {
  /* width: 90%; */
  /* max-width: 600px; */
  background-color: #fff;
  padding: 24px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 8px;
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: left;
}

@media (max-width: 600px) {
  .subscriptions-container {
    padding: 1rem;
  }
  .subscriptions-content {
    padding: 16px; /* Reduced padding on mobile */
  }

  .heading {
    font-size: 20px; /* Smaller heading font on mobile */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/MySubscriptions/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,uBAAuB;EACvB,yBAAyB;EACzB,uBAAuB;EACvB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,sBAAsB;EACtB,aAAa;EACb,2CAA2C;EAC3C,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,aAAa,EAAE,8BAA8B;EAC/C;;EAEA;IACE,eAAe,EAAE,mCAAmC;EACtD;AACF","sourcesContent":[".subscriptions-container {\n  display: flex;\n  flex-direction: column;\n  padding: 2rem;\n  justify-content: center;\n  /* align-items: center; */\n  /* min-height: 100vh; */\n  width: 100%;\n  background-color: #fff;\n}\n\n.subscriptions-content {\n  /* width: 90%; */\n  /* max-width: 600px; */\n  background-color: #fff;\n  padding: 24px;\n  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;\n  border-radius: 8px;\n}\n\n.heading {\n  font-size: 24px;\n  font-weight: bold;\n  margin-bottom: 16px;\n  text-align: left;\n}\n\n@media (max-width: 600px) {\n  .subscriptions-container {\n    padding: 1rem;\n  }\n  .subscriptions-content {\n    padding: 16px; /* Reduced padding on mobile */\n  }\n\n  .heading {\n    font-size: 20px; /* Smaller heading font on mobile */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
