import React, { useState } from 'react'
import { Modal, Button, Radio } from 'antd'
import './index.css'
import { FiVideo } from 'react-icons/fi'
import PrimaryButton from '../ui/Buttons/primaryButton'
import { CloseOutlined } from '@ant-design/icons'
import VideoSettingsOutlinedIcon from '@mui/icons-material/VideoSettingsOutlined'

const RecordingOptionsModal = ({ onStartRecording, setError }) => {
  const [videoOption, setVideoOption] = useState('screenOnly')
  const [audioOption, setAudioOption] = useState('systemAudio')
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleStartRecording = () => {
    onStartRecording({
      videoOption: videoOption,
      audioOption: audioOption
    })
    setIsModalVisible(false) // Close modal after starting recording
  }

  const handleCancel = () => {
    setIsModalVisible(false) // Close modal on cancel
  }

  const getVideoOptionLabel = option => {
    switch (option) {
      case 'screenOnly':
        return 'Screen Only'
      case 'screenAndCamera':
        return 'Screen + Camera'
      case 'cameraOnly':
        return 'Camera Only'
      default:
        return ''
    }
  }

  const getAudioOptionLabel = option => {
    switch (option) {
      case 'systemAudio':
        return 'System Audio'
      case 'micAudio':
        return 'Mic Audio'
      case 'both':
        return 'Both'
      default:
        return ''
    }
  }

  return (
    <div>
      <PrimaryButton
        shape='circle'
        // icon={<FiVideo color='#FF4500' />}
        icon={
          <VideoSettingsOutlinedIcon
            style={{ fontSize: '16px', color: '#1565c0' }}
          />
        }
        onClick={() => setIsModalVisible(true)}
      ></PrimaryButton>

      <Modal
        title={
          <span style={{ color: 'var(--text-color)',  fontFamily: '"Roboto", "Helvetica Neue", sans-serif'}}>Recording Options</span>
        }
        open={isModalVisible}
        onCancel={handleCancel}
        centered
        footer={null}
        closeIcon={<CloseOutlined style={{ color: 'var(--text-color)' }} />}
        styles={{
          content: { backgroundColor: 'var(--bg-color)' },
          header: { backgroundColor: 'var(--bg-color)' },
          body: { backgroundColor: 'var(--bg-color)' }
        }}
      >
        <div className='mb-4'>
          <h3
            className='font-medium mb-2'
            style={{ color: 'var(--text-color)', fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}
          >
            Video Options
          </h3>
          <Radio.Group
            onChange={e => setVideoOption(e.target.value)}
            value={videoOption}
          >
            {['screenOnly', 'screenAndCamera', 'cameraOnly'].map(option => (
              <Radio
                key={option}
                value={option}
                style={{ color: 'var(--text-color)', fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}
              >
                {getVideoOptionLabel(option)}
              </Radio>
            ))}
          </Radio.Group>
        </div>

        <div className='mb-4'>
          <h3
            className='font-medium mb-2'
            style={{ color: 'var(--text-color)', fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}
          >
            Audio Options
          </h3>
          <Radio.Group
            onChange={e => setAudioOption(e.target.value)}
            value={audioOption}
          >
            {['systemAudio', 'micAudio', 'both'].map(option => (
              <Radio
                key={option}
                value={option}
                style={{ color: 'var(--text-color)', fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}
              >
                {getAudioOptionLabel(option)}
              </Radio>
            ))}
          </Radio.Group>
        </div>

        <div
          className='flex'
          style={{
            width: '80%',
            margin: 'auto',
            justifyContent: 'space-around'
          }}
        >
          <Button
            onClick={handleCancel}
            style={{
              backgroundColor: 'orange',
              color: 'white',
              border: 'none',
              fontFamily: '"Roboto", "Helvetica Neue", sans-serif'
            }}
          >
            Cancel
          </Button>
          <Button
            type='primary'
            onClick={handleStartRecording}
            style={{ backgroundColor: 'green', color: 'white', border: 'none', fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}
          >
            Start Recording
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default RecordingOptionsModal
