import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FiBarChart2,
  FiFileText,
  FiCalendar,
  FiClock,
  FiSmartphone,
  FiTrash2,
  FiLogOut,
  FiSearch,
  FiStar,
  FiMessageSquare,
  FiHelpCircle,
  FiGift,
  FiSettings,
  FiMic,
  FiUpload,
  FiVideo,
  FiMenu,
  FiMoon,
  FiSun,
} from "react-icons/fi";
// import solorecallLogo from '../components/img/solorecall_logo.png'
import "../styles/Sidebar.css";
import { useDarkMode } from "../App";
import PrimaryButton from "./ui/Buttons/primaryButton";
import { Divider, Tooltip } from "antd";
import AudioRecorder from "./AudioRecorder";
import { RiVipDiamondLine } from "react-icons/ri";
import RecordingOptionsModal from "./VideoRecorder";
import logo from '../assets/images/logo.png'
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';

const Sidebar = ({
  user,
  onSignOut,
  onRecordScreen,
  onShowAudioRecording,
  onImportFiles,
  onTranscribeMeeting,
  onRecordVideo,
  onSidebarToggle,
  noheader = false,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
  const [screenRec, setScreenRec] = useState(false);
  const [audRec, setAudRec] = useState(false);
  const [ai, setAi] = useState(false);
  const [files, setFiles] = useState(false);
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const sidebarRef = useRef(null);

  // Add refs for tooltip targets
  const uploadButtonRef = useRef(null);
  const uploadIconRef = useRef(null);
  const chartButtonRef = useRef(null);
  const chartIconRef = useRef(null);
  const fileButtonRef = useRef(null);
  const fileIconRef = useRef(null);
  const trashButtonRef = useRef(null);
  const trashIconRef = useRef(null);
  const aiButtonRef = useRef(null);
  const aiIconRef = useRef(null);

  // Add refs for action items
  const actionButtonRefs = useRef([]);
  const actionIconRefs = useRef([]);

  const onScreenRec = () => {
    onRecordScreen();
    setScreenRec(true);
    setAudRec(false);
    setFiles(false);
    setAi(false);
  };

  const onAudioRec = () => {
    onShowAudioRecording();
    setAudRec(true);
    setScreenRec(false);
    setFiles(false);
    setAi(false);
  };

  const onFileImport = () => {
    setFiles(true);
    onImportFiles();
    setScreenRec(false);
    setAudRec(false);
    setAi(false);
  };

  const onAiRedirect = () => {
    setAi(true);
    navigate("/ai-chat");
    setScreenRec(false);
    setAudRec(false);
    setFiles(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      setSidebarOpen(!mobile);
    };

    const handleClickOutside = (event) => {
      if (
        isMobile &&
        sidebarOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target)
      ) {
        setSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile, sidebarOpen]);

  useEffect(() => {
    onSidebarToggle(sidebarOpen);
  }, [sidebarOpen, onSidebarToggle]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const sidebarItems = [
    {
      ref: chartButtonRef,
      iconRef: chartIconRef,
      icon: <FiBarChart2 style={{ color: "#FF1493" }} />,
      text: "Dashboard",
      path: "/dashboard",
    },
    {
      ref: fileButtonRef,
      iconRef: fileIconRef,
      icon: <FiFileText style={{ color: "#9400D3" }} />,
      text: "All Files",
      path: "/files",
    },
    {
      ref: trashButtonRef,
      iconRef: trashIconRef,
      icon: <FiTrash2 style={{ color: "#e53935" }} />,
      text: "Trash",
      path: "/trash",
    },
    {
      ref: aiButtonRef,
      iconRef: aiIconRef,
      icon: <AssistantOutlinedIcon style={{ color: "#00CED1", fontSize: '15px' }} />,
      text: "Chat with AI",
      path: "/ai-chat",
    },
  ];

  const actionItems = [
    {
      icon: <FiUpload style={{ color: "#01e0b8" }} />,
      text: "Import files",
      active: files,
      onClick: onFileImport,
    },
  ];

  // Initialize refs for action items
  useEffect(() => {
    actionButtonRefs.current = actionItems.map(() => React.createRef());
    actionIconRefs.current = actionItems.map(() => React.createRef());
  }, []);

  const bottomItems = [
    {
      // icon: <FiMessageSquare style={{ color: "#7CFC00" }} />, // Lawn Green
      icon: <SupportAgentOutlinedIcon style={{ color: "#ff5722", fontSize: '15px' }} />, // Lawn Green
      text: "Contact Support",
      path: "/support",
    },
    {
      // icon: <FiHelpCircle style={{ color: "#FF69B4" }} />, // Hot Pink
      icon: <TipsAndUpdatesOutlinedIcon style={{ color: "#FF69B4", fontSize: '16px'  }} />, // Hot Pink
      text: "Submit an Idea",
      path: "/submit-idea",
    },
    {
      icon: <FiSettings style={{ color: "#00FF00" }} />, // Lime Green
      text: "Settings",
      path: "/settings",
    },
  ];

  return (
    <>
      {isMobile && !noheader && (
        <div
          className="mobile-header"
          style={{
            padding: "1rem",
            backgroundColor: "var(--sidebar-bg-color)",
            width: "100%",
            // position:'fixed',
            // top:0,
            // zIndex:'1000'
          }}
        >
          <button
            className="sidebar-toggle"
            onClick={toggleSidebar}
            aria-label="Toggle Sidebar"
          >
            <FiMenu />
          </button>
        </div>
      )}
      <div
        ref={sidebarRef}
        className={`sidebar ${sidebarOpen ? "open" : ""} ${
          isDarkMode ? "dark" : ""
        }`}
      >
        {/* <div className='sidebar-logo'>
          <img src={solorecallLogo} alt='SoloRecall Logo' />
        </div> */}

        {user && user.email && (
          <div className="sidebar-user">
            <div className="sidebar-user-avatar" aria-hidden="true">
              {/* {user.email.charAt(0).toUpperCase()} */}
              <img src={logo} alt="logo" />
            </div>
            <div className="sidebar-user-info">
              <p className="sidebar-user-name">
                {user.email.split("@")[0]}'s Workspace
              </p>
              <p className="sidebar-user-email">{user.email}</p>
            </div>
          </div>
        )}
        <div className="sidebar-top-buttons">
          <AudioRecorder secondary={true} username={user && user?.email} />
          <RecordingOptionsModal/>
          {actionItems.map((action, index) => (
            <PrimaryButton
              key={action.id || `button-${index}`}
              ref={actionButtonRefs.current[index]}
              shape="circle"
              onClick={() => {
                action.onClick();
                isMobile && toggleSidebar();
              }}
            >
              <Tooltip 
                title={action.text}
                getPopupContainer={() => actionButtonRefs.current[index]?.current}
              >
                <span ref={actionIconRefs.current[index]} className="icon-wrapper">
                  {action.icon}
                </span>
              </Tooltip>
            </PrimaryButton>
          ))}
        </div>

        <button onClick={onSignOut} className="sidebar-button">
          <FiLogOut />
          <span>Sign Out</span>
        </button>

        <div className="sidebar-search">
          <div className="sidebar-search-container">
            <FiSearch className="sidebar-search-icon" aria-hidden="true" />
            <input
              type="text"
              placeholder="Quick Find"
              className="sidebar-search-input"
              aria-label="Quick Find"
            />
            <span className="sidebar-search-shortcut" aria-hidden="true">
              Ctrl K
            </span>
          </div>
          <Divider
            style={{
              backgroundColor: "var(--primary-color)",
              margin: "10px 0 !important",
            }}
          />
        </div>

        <nav className="sidebar-nav">
          {sidebarItems.map((item, index) => (
            <Link
              key={item.id || `item-${index}`}
              to={item.path}
              className={`sidebar-nav-item ${
                location.pathname === item.path ? "active" : ""
              }`}
              onClick={() => isMobile && toggleSidebar()}
              ref={item.ref}
            >
              <span className="sidebar-nav-item-content">
                <span ref={item.iconRef} className="icon-wrapper">
                  {item.icon}
                </span>
                <span>{item.text}</span>
              </span>
            </Link>
          ))}
          {bottomItems.map((item, index) => (
            <Link
              key={item.id || `item-${index}`}
              to={item.path}
              className={`sidebar-bottom-item ${
                location.pathname === item.path ? "active" : ""
              }`}
              onClick={() => {
                if (isMobile) {
                  toggleSidebar();
                }
              }}
            >
              {item.icon}
              <span>{item.text}</span>
              {item.isNew && <span className="sidebar-new-badge">NEW</span>}
            </Link>
          ))}

          <Link
            style={{
              backgroundColor: "#7F8C8D",
              color: "white",
            }}
            to={"/subscription"}
            className={`sidebar-bottom-item-v2 `}
            onClick={() => {
              if (isMobile) {
                toggleSidebar();
              }
            }}
          >
            {<RiVipDiamondLine />}
            <span>{"Subscription Plans"}</span>
            {/* {item.isNew && <span className='sidebar-new-badge'>NEW</span>} */}
          </Link>

          {/* <Divider
            style={{
              backgroundColor: 'var(--primary-color)',
              margin: '10px 0 !important'
            }}
          />
          {actionItems.map((action, index) => (
            <button
              key={index}
              onClick={() => {
                action.onClick()
                isMobile && toggleSidebar()
              }}
              className={`sidebar-action-button ${
                action.active === true ? 'active' : ''
              }`}
            >
              <span className='sidebar-nav-item-content'>
                {action.icon}
                <span>{action.text}</span>
              </span>
            </button>
          ))} */}
        </nav>

        {/* <div className="sidebar-actions">
          <h2 className="sidebar-actions-title">Quick Actions</h2>
          {actionItems.map((action, index) => (
            <button
              key={index}
              onClick={() => {
                action.onClick();
                isMobile && toggleSidebar();
              }}
              className="sidebar-action-button"
            >
              {action.icon}
              <span>{action.text}</span>
            </button>
          ))}
        </div> */}

        {/* <div className="sidebar-bottom">
          {bottomItems.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className="sidebar-bottom-item"
              onClick={() => {
                if (isMobile) {
                  toggleSidebar();
                }
              }}
            >
              {item.icon}
              <span>{item.text}</span>
              {item.isNew && (
                <span className="sidebar-new-badge">
                  NEW
                </span>
              )}
            </Link>
          ))} */}
        <button
          className="dark-mode-toggle"
          onClick={toggleDarkMode}
          aria-label="Toggle Dark Mode"
        >
          {isDarkMode ? <FiSun /> : <FiMoon />}
          <span style={{ marginLeft: "1rem" }}>
            {isDarkMode ? " Light Mode" : " Dark Mode"}
          </span>
        </button>
        {/* </div> */}
      </div>
      {isMobile && sidebarOpen && (
        <div className="sidebar-overlay" onClick={toggleSidebar} />
      )}
    </>
  );
};

// Add CSS for the icon wrapper if not already present
const styles = `
  .icon-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .sidebar-nav-item-content .icon-wrapper {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;

// Add the styles to the document
if (!document.getElementById('sidebar-styles')) {
  const styleSheet = document.createElement("style");
  styleSheet.id = 'sidebar-styles';
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);
}

export default Sidebar;
