import { createClient } from '@supabase/supabase-js';

// console.log('Environment variables: updated ', process.env);
// console.log('REACT_APP_SUPABASE_URL: updated', process.env.REACT_APP_SUPABASE_URL);
// console.log('REACT_APP_SUPABASE_ANON_KEY: updated', process.env.REACT_APP_SUPABASE_ANON_KEY);

// const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseUrl = 'https://blfrvmapphmzvushkvea.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJsZnJ2bWFwcGhtenZ1c2hrdmVhIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjEzNDIyOTAsImV4cCI6MjAzNjkxODI5MH0.lS3S-z2pMEnRs7zIBRr1bxLM00dhlk2q3pGCetlPYAU'
// const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
// console.log('supabaseUrl: ', supabaseUrl);
// console.log('supabaseAnonKey: ', supabaseAnonKey);
if (!supabaseUrl) {
  throw new Error('Supabase URL is missing. Check your .env file for REACT_APP_SUPABASE_URL.');
}

if (!supabaseAnonKey) {
  throw new Error('Supabase anonymous key is missing. Check your .env file for REACT_APP_SUPABASE_ANON_KEY.');
}

const supabase = createClient(supabaseUrl, supabaseAnonKey);

export { supabase };

export const signUp = async (email, password, firstName, lastName) => {
  try {
    console.log('Attempting to sign up with:', { email, firstName, lastName });
    
    // Sign up the user with email confirmation
    const { data: authData, error: signUpError } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          first_name: firstName,
          last_name: lastName
        },
        emailRedirectTo: `${window.location.origin}/auth/callback`
      }
    });

    if (signUpError) {
      console.error('Signup error:', signUpError);
      throw signUpError;
    }
    
    console.log('Sign up successful:', authData);

    // Create user folder in storage only after successful signup
    if (authData.user) {
      try {
        // Create the user's folder with a .keep file
        const { error: storageError } = await supabase.storage
          .from('recordings')
          .upload(`${authData.user.id}/.keep`, new Blob([''], { type: 'text/plain' }), {
            upsert: true
          });

        if (storageError) {
          console.error('Error creating user folder:', storageError);
        }
      } catch (storageError) {
        console.error('Error in storage operation:', storageError);
      }

      // Return success with verification message
      return { 
        user: authData.user, 
        error: null,
        message: 'Please check your email to verify your account before logging in.'
      };
    }

    return { user: authData.user, error: null };
  } catch (error) {
    console.error('Error during sign up process:', error);
    return { 
      user: null, 
      error: error.message || 'An error occurred during signup'
    };
  }
};

// Add email verification check
export const checkEmailVerification = async (email) => {
  try {
    const { data: { user }, error } = await supabase.auth.getUser();
    
    if (error) throw error;
    
    if (!user?.email_confirmed_at) {
      throw new Error('Please verify your email before logging in.');
    }
    
    return { verified: true };
  } catch (error) {
    console.error('Error checking email verification:', error);
    return { verified: false, error };
  }
};

// Update signIn to handle errors better
export const signIn = async (email, password) => {
  try {
    console.log('Attempting to sign in with:', email);
    
    const { data, error } = await supabase.auth.signInWithPassword({ 
      email, 
      password 
    });
    
    if (error) {
      console.error('Sign in error:', error);
      throw error;
    }
    
    console.log('Sign in successful:', data);
    return data;
  } catch (error) {
    console.error('Sign in error:', error);
    throw new Error(error.message || 'Invalid login credentials');
  }
};

export const signInWithGoogle = async () => {
  console.log('Attempting to sign in with Google');
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'google'
  });
  if (error) {
    console.error('Google sign in error:', error);
    throw error;
  }
  console.log('Google sign in successful:', data);
};

export const signOut = async () => {
  console.log('Attempting to sign out');
  const { error } = await supabase.auth.signOut();
  if (error) {
    console.error('Sign out error:', error);
    throw error;
  }
  console.log('Sign out successful');
};

export const getCurrentUser = async () => {
  console.log('Fetching current user');
  const { data: { user }, error } = await supabase.auth.getUser();
  if (error) {
    console.error('Error fetching current user:', error);
    throw error;
  }
  console.log('Current user:', user);
  return user;
};

export const onAuthStateChange = (callback) => {
  console.log('Setting up auth state change listener');
  return supabase.auth.onAuthStateChange((event, session) => {
    console.log('Auth state changed:', event, session);
    callback(event, session);
  });
};
